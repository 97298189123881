import request from '../network'

class FeeRuleApi {

  query (params, data) {
    return request({
      url: '/api/FeeRule/query',
      method: 'post',
      params,
      data
    })
  }

  queryAll () {
    return request({
      url: '/api/FeeRule/queryAll',
      method: 'get'
    })
  }

  create (data = {}) {
    return request({
      url: '/api/FeeRule',
      method: 'post',
      data
    })
  }

  update (data = {}) {
    return request({
      url: '/api/FeeRule/' + data.id,
      method: 'put',
      data
    })
  }

  delete (data = {}) {
    return request({
      url: '/api/FeeRule/' + data.id,
      method: 'delete',
      data
    })
  }

  show (id) {
    return request({
      url: '/api/FeeRule/' + id,
      method: 'get'
    })
  }
}


export { FeeRuleApi as default };
