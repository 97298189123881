import { SmsCaptchaApi } from '../../services/api';

let smsCaptchaApi = new SmsCaptchaApi();

const state = {

};

const getters = {

};

const actions = {

  send(store, data) {
    return new Promise((resolve, reject) => {
      smsCaptchaApi.send(data).then(response => {
        resolve(response.data)
      }).catch(error => {
        reject(error)
      })
    })
  }

};



const mutations = {

};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
