import request from '../network'

class SmsCaptchaApi {

  send (data) {
    return request({
      url: '/api/sms/captcha/send',
      method: 'post',
      data
    })
  }


}


export { SmsCaptchaApi as default };
