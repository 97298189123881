import zhHans from 'vuetify/es5/locale/zh-Hans'

export default {
  label: '简体中文',
  title: '芯动健康',

  login: {
    label: '登录',
    title: '芯动卫士',
    username: '用户名',
    password: '密码',
    agreeTo: '已阅读并同意',
    userAgreement: '用户协议',
    readAndAgree: '请阅读并同意以上条款'
  },

  profileMenus: {
    profile: '个人偏好',
    settings: '系统设置',
    logout: '系统登出',
    resetPassword: '重置密码'
  },

  route: {
    root: '根目录',
    deault: '默认目录',
    login: '登录',
    home: '首页',
    about: '关于',
    helloWorld: '嗨世界',
    dashboard: '仪表盘',
    people: '用户',
    system: '系统管理',
    systemUser: '机构用户',
    systemUserCreate: '创建机构用户',
    systemUserEdit: '编辑机构用户',
    systemDepartment: '机构管理',
    systemRole: '角色管理',
    equipment: '设备管理',
    equipmentList: '设备列表',
    equipmentDistribute: '设备发放',
    equipmentChange: '设备更换',
    equipmentCreate: '设备入库',
    equipmentTemplate: '设备模板',
    equipmentTemplateCreate: '创建设备模板',
    equipmentTemplateEdit: '编辑设备模板',
    patient: '患者管理',
    patientList: '患者列表',
    patientCreate: '新建患者',
    patientEdit: '编辑患者',
    patientShow: '患者信息',
    patientSign: '患者签约',
    patientHealthReports: "健康报告",
    patientHealthDatas: "患者数据",
    homeDoctor: '家医管理',
    homeDoctorList: '家医列表',
    homeDoctorCreate: '新建家医',
    homeDoctorEdit: '编辑家医',
    healthReportShow: "健康详情",
    contract: '合同管理',
    contractList: '合同列表',
    contractCreate: '新建合同',
    contractEdit: '编辑合同',
    feeRule: '费用管理',
    feeRuleList: '费用列表',
    feeRuleCreate: '新建费用',
    feeRuleEdit: '编辑费用',
    callCenter: "呼叫中心",
    callCenterList: "呼叫列表",
    callCenterMap: "呼叫地图",
    callCenterShow: "呼叫详情",
    temperature: '体温监测',
    temperatureDashboard: '数据监测',
    temperatureDetail:'监测详情',
    forbidden: '禁止',
    notFound: '未找到',
    serverError: '服务端错误',
    resetPassword: '重置密码'
  },

  system: {
    department: '机构',
    region: '区域'
  },

  department: {
    label: '机构',
    list: '机构列表',
    depNo: '机构编码',
    parent: '上级机构',
    name: '机构名称',
    level: '机构级别',
    description: '机构描述',
    selectADepartment: '请选择机构',
    changeAdmin: '更换管理员',
    selectAdminUser: '选择管理员',
    addAdminUser: '添加管理员'
  },

  user: {
    label: '用户',
    list: '用户列表',
    dep: '所属机构',
    realName: '姓名',
    username: '登录名',
    address: '家庭住址',
    birthday: '出生日期',
    homePhone: '家庭电话',
    phone: '手机号',
    email: '电子邮箱',
    credentialNum: '身份证号',
    isMonitorScreenUser: '大屏用户'
  },

  role: {
    label: '角色',
    list: '角色列表',
    roleName: '角色名称',
    showName: '显示名称'
  },

  equipment: {
    label: '设备',
    list: '设备列表',
    emId: '设备模板',
    purchaseDate: '采购日期',
    storeDate: '入库日期',
    proNumber: '生产批号',
    deviceId: '设备编号',
    state: '状态',
    patient: '使用者',
    distribute: '设备发放',
    scanCodeConfirm: '扫码确认',
    store: '设备入库',
    batchImport: '批量导入',
    uploadList: '上传设备列表',
    confirmDistribute: '确认发放',
    chooseEquipmentTemplate: '请选择设备模板',
    changeEquipment: '更换设备',
    downloadTip: '设备入库，需上传excel模板，如没有请下载。'
  },

  equipmentTemplate: {
    label: '设备模板',
    list: '模板列表',
    company: '厂家名称',
    companyAddr: '厂家地址',
    companyTel: '厂家联系方式',
    deposit: '设备押金',
    name: '设备名称',
    no: '设备型号',
    price: '设备售价',
    recst: 'Recst',
    regid: '医疗器械注册证号',
    rental: '租赁价格',
    serviceDate: '保质日期',
    type: '设备类型',
    create: '创建模板',
    category: '设备租售类型'
  },

  equipmentCategory: {
    leasing: '租赁设备',
    sale: '出售设备',
    leasingAndSale: '可租可售'
  },

  equipmentState: {
    ready: '未分配' ,
    free: '空闲',
    grant: '已发放',
    lost: '遗失',
    damaged: '损坏'
  },

  patient: {
    label: '患者',
    list: '患者列表',
    name: '姓名',
    national: '民族',
    diseases: '既往病史',
    disabilityStatus: '是否失能',
    institutionAndDoctor: '机构和医生',
    createSign: '创建并签约',
    batchCreateSign: '批量创建并签约',
    sign: '签约',
    informtion: '患者信息',
    institution: '签约机构',
    address: '家庭住址',
    doctor: '责任医生',
    downloadTip: '批量导入患者，需上传excel模板，如没有请下载'
  },

  contract: {
    label: '合同',
    list: '合同列表',
    contractName: '合同名称',
    serviceType: '服务内容',
    serviceItem: '服务项目',
    operator: '创建人'
  },

  feeRule: {
    label: '费用规则',
    list: '费用规则列表',
    feeFrequency: '费用频次',
    feeType: '费用类型',
    feeValue: '费用',
    ruleName: '规则名称',
    ruleType: '规则类型',
    operator: '创建人'
  },

  ruleType: {
    standard: '标准',
    expert: '专家'
  },

  homeDoctor: {
    label: '家庭医生',
    list: '家医列表'
  },

  sosAlarm: {
    label: '紧急呼叫',
    list: '紧急呼叫列表',
    address: '呼救位置',
    alarmTime: '呼救时间',
    status: '处理状态',
    doctorName: '急救医生',
    progress: '立即处理',
    show: '查看',
    progressContent: '处理内容',
    age: '岁',
    ages: '年龄',
    patientInfo: '呼救人信息',
    callInfo: '呼救过程信息',
    progressInfo: '呼救完成信息',
    progressOperator: '处理人',
    progressTime: '处理时间',
    progressType: '处理方式'
  },

  healthReport: {
    label: '健康报告',
    list: '健康报告列表',
    reportName: '报告名称',
    reportStatus: '是否购买',
    startDate: '监测开始',
    endDate: '监测结束',
    createdAt: '生成日期',
    frequency: '周期',
    healthReportDates: '监测时间',
    analysisResult: '分析结果',
    heartRate: '心率',
    bloodOxygen: '血氧',
    sport: '运动',
    bloodPressure: '血压',
    time: '测量时间',
    systolic: '高压',
    diastolic: '低压',
    conclusion: '结论',
    bloodOxygenLabel: '血氧饱和度',
    timesPerMinute: '次/分',
    steps: '步'
  },

  monitorScreen: {
    summaryLevelTitle1: '全县智慧医疗数据大屏',
    summaryLevelTitle2: '全镇智慧医疗数据大屏',
    sosDataTitle: '呼叫服务统计',
    sosDataTotalNum: '共处理呼救总数',
    sosDataTodayNum: '今日处理呼救数量',
    sosDataWeekNum: '本周处理呼救数量',
    sosDataMonthNum: '本月处理呼救数量'
  },

  progressType: {
    userMisreport: '用户误触',
    telephoneGuidance: '电话指导',
    visitRescue: '上门施救',
    other: '其他'
  },

  sosAlarmStatus: {
    ready: '未处理',
    finished: '已处理'
  },

  serviceType: {
    reportService: '报告服务',
    sosAlarm: 'SOS报警服务',
    healthConsultation: '健康咨讯宣讲'
  },

  serviceItem: {
    healthReport: '健康综合报告',
    ecgReport: '心电报告',
    ecgStandard: '标准心电报告',
    ecgExpert: '专家心电报告'
  },

  serviceFrequency: {
    dayFrequency: '1 日 1 次',
    weekFrequency: '1 周 1 次',
    monthFrequency: '1 月 1 次',
    quarterFrequency: '1 季 1 次',
    seriesFrequency: '单次',
    combinationPackage: '套餐'
  },

  disabilityStatus: {
    disability: '失能',
    halfDisability: '半失能',
    noDisability: '不失能'
  },

  signStatus: {
    unsigned: '未签约',
    signed: '已签约',
  },

  temperature: {
    dashboardTotalRecords: '累计次数',
    dashboardWarnRecords: '异常次数',
    dashboardTotalPersons: '累计人数',
    dashboardWarnPersons: '异常人数',
    dashboardWarnRecordsTendency: '异常次数趋势',
    dashboardWarnRecordsRatio: '异常次数占比',
    dashboardWarnPersonsTendency: '异常人数趋势',
    dashboardWarnPersonsRatio: '异常人数占比',
    dashboardDataList: '数据列表',
    dashboardDataMap: '数据地图',
    dashboardRegion: '地区',
    detailAge: '岁',
    detailTime: '监测时间',
    detailTemperature: '体温',
    detailInstitution: '监测机构'
  },

  region: {
    label: '所属区域',
    province: '省/直辖市',
    city: '市/区',
    county: '县/区',
    town:'乡镇/街道',
    village: '村/居委会'
  },

  gender: {
    male: '男',
    female: '女',
    unknown: '未知'
  },

  common: {
    actions: '操作',
    submit: '提交',
    create: '创建',
    cancel: '取消',
    name: '姓名',
    gender: '性别',
    age: '年龄',
    address: '地址',
    birthday: '出生日期',
    description: '备注信息',
    password: '密码',
    confirmPassword: '确认密码',
    close: '关闭',
    delete: '删除',
    distribute: '发放',
    goBack: '返回',
    search: '查询',
    yuan: '元',
    time: '次',
    week: '周',
    month: '月',
    warning: '警告',
    ok: '确定',
    phone: '手机号',
    chooseFiles: '选择文件',
    clickDownload: '点击下载',
    belongToArea: '所属区域',
    batchCreate: '批量创建',
    identityCard: '身份证',
    idAddress: '身份证地址',
    save: '保存',
    createdAt: '创建时间',
    startDate: '开始时间',
    endDate: '结束时间',
    to: '至',
    noData: '无数据',
    max: '最大',
    min: '最小',
    highest: '最高',
    lowest: '最低',
    high: '高',
    low: '低',
    avg: '平均',
    sum: '累计',
    verificationCode: '验证码',
    sendVerificationCode: '发送验证码',
    changeSuccess: '修改成功',
    history: '历史数据',
    detailDatas: '详细数据',
    true: '是',
    false: '否'
  },

  rule: {
    required: '请输入{0}',
    selected: '请选择{0}',
    sameAs: '需和{0}一致',
    format: '{0}格式不正确',
    mustIsExcel: '文件格式错误，请上传Excel文件！'
  },

  message: {
    deleteConfirm: '确定要删除',
    recyclingConfirm: '确定要回收',
    addMonitorScreenUserConfirm: '确定将 {0} 添加为大屏用户',
    delMonitorScreenUserConfirm: '确定取消 {0} 为大屏用户  ',
    addEpidemicMonitorUserConfirm: '确定将 {0} 添加为体温监测用户',
    delEpidemicMonitorUserConfirm: '确定取消 {0} 为体温监测用户  ',
    needSelectedOne: '请先选择一条记录！',
    systemErr: '系统错误，请稍后再试',
    resourceExistedErr: '{0}已存在，请重新输入！',
    notContractedErr: '患者尚未签约！',
    usernameOrPasswordErr: '用户名或密码错误！',
    importTooltips: '成功: {0}条, 失败: {1}条！'
  },

  ...zhHans
}
