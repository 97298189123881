import request from '../network'

class EquipmentApi {

  query (params, data) {
    return request({
      url: '/api/equipmentstore/query',
      method: 'post',
      params,
      data
    })
  }

  create (data = {}) {
    return request({
      url: '/api/equipmentstore',
      method: 'post',
      data
    })
  }

  update (data = {}) {
    return request({
      url: '/api/equipmentstore/' + data.id,
      method: 'put',
      data
    })
  }

  delete (data = {}) {
    return request({
      url: '/api/equipmentstore/' + data.id,
      method: 'delete',
      data
    })
  }

  show (id) {
    return request({
      url: '/api/equipmentstore/' + id,
      method: 'get'
    })
  }

  grant (id, params) {
    return request({
      url: '/api/equipmentstore/grant/' + id,
      method: 'post',
      params
    })
  }

  downloadTemplate () {
    return request({
      url: '/api/equipmentstore/download/template',
      method: 'get',
      responseType: 'blob'
    })
  }

  importById (id, data) {
    return request({
      url: '/api/equipmentstore/import/' + id,
      method: 'post',
      data
    })
  }

  queryAllAvailablePage (params, data) {
    return request({
      url: '/api/equipmentstore/queryAllAvailablePage',
      method: 'post',
      params,
      data
    })
  }

  change (id, params) {
    return request({
      url: '/api/equipmentstore/change/' + id,
      method: 'post',
      params
    })
  }

  Recycling (id, params) {
    return request({
      url: '/api/equipmentstore/recycling/' + id,
      method: 'post',
      params
    })
  }
}


export { EquipmentApi as default };
