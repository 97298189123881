import request from '../network'
import qs from 'qs'

class HealthReportApi {

  query (params, data) {
    return request({
      url: '/api/IotHealthReport/query',
      method: 'post',
      params,
      data,
      paramsSerializer: params => {
        return qs.stringify(params, {arrayFormat: 'repeat'})
      }
    })
  }

  queryAll () {
    return request({
      url: '/api/IotHealthReport/queryAll',
      method: 'get'
    })
  }

  show (id) {
    return request({
      url: '/api/IotHealthReport/show/' + id,
      method: 'get'
    })
  }

}


export { HealthReportApi as default };
