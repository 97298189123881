import * as types from '../mutation-types';
import { HealthDataApi } from '../../services/api';

let healthDataApi = new HealthDataApi();

const state = {
  currentBloodOxygen: null,
  currentBloodPressure: null,
  currentHeartRate: null,
  currentSports: null,
};

const getters = {
  currentBloodOxygen: state => state.currentBloodOxygen,
  currentBloodPressure: state => state.currentBloodPressure,
  currentHeartRate: state => state.currentHeartRate,
  currentSports: state => state.currentSports
};

const actions = {

  findBloodOxygenTrendDataByPatientIdAndRangeDate(store, { params, data }) {
    return new Promise((resolve, reject) => {
      healthDataApi.findBloodOxygenTrendDataByPatientIdAndRangeDate(params, data).then(response => {
        store.commit(types.HEALTHDATA_BLOODOXYGENS_SET_CURRENT, response.data)
        resolve(response.data)
      }).catch(error => {
        reject(error)
      })
    })
  },

  findBloodOxygenTrendDataByPatientIdAndTargetDate(store, params) {
    return new Promise((resolve, reject) => {
      healthDataApi.findBloodOxygenTrendDataByPatientIdAndTargetDate(params).then(response => {
        store.commit(types.HEALTHDATA_BLOODOXYGENS_SET_CURRENT, response.data)
        resolve(response.data)
      }).catch(error => {
        reject(error)
      })
    })
  },

  findBloodPressureTrendDataByPatientIdAndRangeDate(store, { params, data }) {
    return new Promise((resolve, reject) => {
      healthDataApi.findBloodPressureTrendDataByPatientIdAndRangeDate(params, data).then(response => {
        store.commit(types.HEALTHDATA_BLOODPRESSURES_SET_CURRENT, response.data)
        resolve(response.data)
      }).catch(error => {
        reject(error)
      })
    })
  },

  findBloodPressureTrendDataByPatientIdAndTargetDate(store, params) {
    return new Promise((resolve, reject) => {
      healthDataApi.findBloodPressureTrendDataByPatientIdAndTargetDate(params).then(response => {
        store.commit(types.HEALTHDATA_BLOODPRESSURES_SET_CURRENT, response.data)
        resolve(response.data)
      }).catch(error => {
        reject(error)
      })
    })
  },

  findHeartRateTrendDataByPatientIdAndRangeDate(store, { params, data }) {
    return new Promise((resolve, reject) => {
      healthDataApi.findHeartRateTrendDataByPatientIdAndRangeDate(params, data).then(response => {
        store.commit(types.HEALTHDATA_HEARTRATES_SET_CURRENT, response.data)
        resolve(response.data)
      }).catch(error => {
        reject(error)
      })
    })
  },

  findHeartRateTrendDataByPatientIdAndTargetDate(store, params) {
    return new Promise((resolve, reject) => {
      healthDataApi.findHeartRateTrendDataByPatientIdAndTargetDate(params).then(response => {
        store.commit(types.HEALTHDATA_HEARTRATES_SET_CURRENT, response.data)
        resolve(response.data)
      }).catch(error => {
        reject(error)
      })
    })
  },

  findSportTrendDataByPatientIdAndRangeDate(store, { params, data }) {
    return new Promise((resolve, reject) => {
      healthDataApi.findSportTrendDataByPatientIdAndRangeDate(params, data).then(response => {
        store.commit(types.HEALTHDATA_SPORTS_SET_CURRENT, response.data)
        resolve(response.data)
      }).catch(error => {
        reject(error)
      })
    })
  },

  findSportTrendDataByPatientIdAndTargetDate(store, params) {
    return new Promise((resolve, reject) => {
      healthDataApi.findSportTrendDataByPatientIdAndTargetDate(params).then(response => {
        store.commit(types.HEALTHDATA_SPORTS_SET_CURRENT, response.data)
        resolve(response.data)
      }).catch(error => {
        reject(error)
      })
    })
  },

  queryAllBloodOxygenByPatientIdAndTargetDateWithPage (store, params) {
    return new Promise((resolve, reject) => {
      healthDataApi.queryAllBloodOxygenByPatientIdAndTargetDateWithPage(params).then(response => {
        resolve(response.data)
      }).catch(error => {
        reject(error)
      })
    })
  },


  queryAllBloodPressureByPatientIdAndTargetDateWithPage (store, params) {
    return new Promise((resolve, reject) => {
      healthDataApi.queryAllBloodPressureByPatientIdAndTargetDateWithPage(params).then(response => {
        resolve(response.data)
      }).catch(error => {
        reject(error)
      })
    })
  },

  queryAllHeartRateByPatientIdAndTargetDateWithPage (store, params) {
    return new Promise((resolve, reject) => {
      healthDataApi.queryAllHeartRateByPatientIdAndTargetDateWithPage(params).then(response => {
        resolve(response.data)
      }).catch(error => {
        reject(error)
      })
    })
  },

  queryAllSportByPatientIdAndTargetDateWithPage(store, params) {
    return new Promise((resolve, reject) => {
      healthDataApi.queryAllSportByPatientIdAndTargetDateWithPage(params).then(response => {
        resolve(response.data)
      }).catch(error => {
        reject(error)
      })
    })
  }

};


const mutations = {

  [types.HEALTHDATA_BLOODOXYGENS_SET_CURRENT] (state, item) {
    state.currentBloodOxygen = item;
  },

  [types.HEALTHDATA_BLOODPRESSURES_SET_CURRENT] (state, item) {
    state.currentBloodPressure = item;
  },

  [types.HEALTHDATA_HEARTRATES_SET_CURRENT] (state, item) {
    state.currentHeartRate = item;
  },

  [types.HEALTHDATA_SPORTS_SET_CURRENT] (state, item) {
    state.currentSports = item;
  }

};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
