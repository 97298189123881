import request from '../network'

class ContractApi {

  query (params, data) {
    return request({
      url: '/api/Contract/query',
      method: 'post',
      params,
      data
    })
  }

  queryAll () {
    return request({
      url: '/api/Contract/queryAll',
      method: 'get'
    })
  }

  create (data = {}) {
    return request({
      url: '/api/Contract',
      method: 'post',
      data
    })
  }

  update (data = {}) {
    return request({
      url: '/api/Contract/' + data.id,
      method: 'put',
      data
    })
  }

  delete (data = {}) {
    return request({
      url: '/api/Contract/' + data.id,
      method: 'delete',
      data
    })
  }

  show (id) {
    return request({
      url: '/api/Contract/' + id,
      method: 'get'
    })
  }
}


export { ContractApi as default };
