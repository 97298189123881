import * as types from '../mutation-types';
import { UserApi } from '../../services/api';

let userApi = new UserApi();

const state = {
    currentUser: null,
    users: []
};

const getters = {
  currentUser: state => state.currentUser,
  users: state => state.users
};

const actions = {
  query(store, { params, data }) {
    return new Promise((resolve, reject) => {
      userApi.query(params, data).then(response => {
        store.commit(types.USERS_SET, response.data.content)
        resolve(response.data)
      }).catch(error => {
        reject(error)
      })
    })
  },

  create (store, data) {
    return userApi.create(data).then(response => {
      store.commit(types.USERS_ADD, response.data);
      return Promise.resolve(response.data);
    }).catch(err => {
      if (err) {
        return Promise.reject(err);
      }
    });
  },

  update (store, data) {
    return userApi.update(data).then(response => {
      store.commit(types.USERS_UPDATE, response.data);
      return Promise.resolve(response.data);
    }).catch(err => {
      if (err) {
        return Promise.reject(err);
      }
    });
  },

  delete (store, data = {}) {
    return userApi.delete(data).then( () => {
      store.commit(types.USERS_DELETE, data);
      return Promise.resolve();
    }).catch(err => {
      if (err) {
        return Promise.reject(err);
      }
    });
  },

  show (store, id = null) {
    return userApi.show(id).then( response => {
      store.commit(types.USERS_SET_CURRENT, response.data);
      return Promise.resolve();
    }).catch(err => {
      if (err) {
        return Promise.reject(err);
      }
    });
  },

  queryAllByDepId (store, depId = null) {
    return userApi.queryAllByDepId(depId).then( response => {
      store.commit(types.USERS_SET, response.data);
      return Promise.resolve();
    }).catch(err => {
      if (err) {
        return Promise.reject(err);
      }
    });
  },

  resetPassword (store, data) {
    return userApi.resetPassword(data).then(response => {
      return Promise.resolve(response.data);
    }).catch(err => {
      if (err) {
        return Promise.reject(err);
      }
    });
  },

  setMonitorScreenUser (store, user) {
    return userApi.setMonitorScreenUser(user.id).then(response => {
      user.isMonitorScreenUser = true
      store.commit(types.USERS_UPDATE, user);
      return Promise.resolve(response.data);
    }).catch(err => {
      if (err) {
        return Promise.reject(err);
      }
    });
  },

  unSetMonitorScreenUser (store, user) {
    return userApi.unSetMonitorScreenUser(user.id).then(response => {
      user.isMonitorScreenUser = false
      store.commit(types.USERS_UPDATE, user);
      return Promise.resolve(response.data);
    }).catch(err => {
      if (err) {
        return Promise.reject(err);
      }
    });
  },

  setEpidemicMonitorUser (store, user) {
    return userApi.setEpidemicMonitorUser(user.id).then(response => {
      user.isEpidemicMonitorUser = true
      store.commit(types.USERS_UPDATE, user);
      return Promise.resolve(response.data);
    }).catch(err => {
      if (err) {
        return Promise.reject(err);
      }
    });
  },

  unSetEpidemicMonitorUser (store, user) {
    return userApi.unSetEpidemicMonitorUser(user.id).then(response => {
      user.isEpidemicMonitorUser = false
      store.commit(types.USERS_UPDATE, user);
      return Promise.resolve(response.data);
    }).catch(err => {
      if (err) {
        return Promise.reject(err);
      }
    });
  }
};



const mutations = {
  [types.USERS_SET_CURRENT] (state, user) {
      state.currentUser = user;
  },
  [types.USERS_ADD] (state, user) {
      if (!state.users) {
          state.users = [];
      }
      state.users.push(user);
  },
  [types.USERS_SET] (state, items) {
      state.users = items;
  },
  [types.USERS_UPDATE] (state, user) {
      for (let i = 0; i < state.users.length; i++) {
          if (state.users[i].id === user.id) {
              state.users.splice(i, 1, user)
          }
      }
  },
  [types.USERS_DELETE] (state, user) {
      state.users = state.users.filter(item => item.id !== user.id);
  }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
