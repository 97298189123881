<template>
  <v-container fluid class="fill-height">
    <navigation-drawer-default ref="drawer"/>
    <app-bar-default @side-icon-click="handleDrawerVisiable"/>
    <v-main class="fill-height" app>
      <v-container class="fill-height align-start" fluid>
        <v-fade-transition mode="out-in">
          <router-view />
        </v-fade-transition>
      </v-container>
    </v-main>
    <v-footer app> </v-footer>
  </v-container>
</template>

<script>
export default {
  name: 'LayoutDefault',

  components: {
    AppBarDefault: () => import('./AppBar'),
    NavigationDrawerDefault: () =>
      import('./NavigationDrawer')
  },

  data: () => ({
    expandOnHover: false
  }),
  methods: {
    handleDrawerVisiable() {
      this.$refs.drawer.toggleDrawer();
    }
  }
};
</script>
