import * as types from '../mutation-types';
import { SosAlarmApi } from '../../services/api';

let sosAlarmApi = new SosAlarmApi();

const state = {
    currentSosAlarm: null,
    sosAlarms: []
};

const getters = {
  currentSosAlarm: state => state.currentSosAlarm,
  sosAlarms: state => state.sosAlarms
};

const actions = {

  query(store, { params, data }) {
    return new Promise((resolve, reject) => {
      sosAlarmApi.query(params, data).then(response => {
        store.commit(types.SOSALARMS_SET, response.data.content)
        resolve(response.data)
      }).catch(error => {
        reject(error)
      })
    })
  },

  queryAllReadySosAlarm(store) {
    return new Promise((resolve, reject) => {
      sosAlarmApi.queryAllReadySosAlarm().then(response => {
        store.commit(types.SOSALARMS_SET, response.data)
        resolve(response.data)
      }).catch(error => {
        reject(error)
      })
    })
  },

  queryAllSosAlarm (store, params) {
    return new Promise((resolve, reject) => {
      sosAlarmApi.queryAllSosAlarm(params).then(response => {
        store.commit(types.SOSALARMS_SET, response.data.content)
        resolve(response.data)
      }).catch(error => {
        reject(error)
      })
    })
  },

  progress (store, { id, data}) {
    return new Promise((resolve, reject) => {
      sosAlarmApi.progress(id, data).then( () => {
        store.commit(types.SOSALARMS_UPDATE, id)
        resolve()
      }).catch(error => {
        reject(error)
      })
    })
  },

  show (store, id = null) {
    return sosAlarmApi.show(id).then( response => {
      store.commit(types.SOSALARMS_SET_CURRENT, response.data);
      return Promise.resolve();
    }).catch(err => {
      if (err) {
        return Promise.reject(err);
      }
    });
  }

};



const mutations = {
  [types.SOSALARMS_SET_CURRENT] (state, sosAlarm) {
      state.currentSosAlarm = sosAlarm;
  },

  [types.SOSALARMS_ADD] (state, sosAlarm) {
      if (!state.sosAlarms) {
          state.sosAlarms = [];
      }
      state.sosAlarms.push(sosAlarm);
  },

  [types.SOSALARMS_SET] (state, items) {
      state.sosAlarms = items;
  },

  [types.SOSALARMS_UPDATE] (state, id) {
      for (let i = 0; i < state.sosAlarms.length; i++) {
          if (state.sosAlarms[i].id === id) {
              state.sosAlarms[i].status = 'FINISHED'
              //state.sosAlarms[i] = state.sosAlarms[i]
          }
      }
  },

  [types.SOSALARMS_DELETE] (state, sosAlarm) {
      state.sosAlarms = state.sosAlarms.filter(item => item.id !== sosAlarm.id);
  }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
