import * as types from '../mutation-types';
import { AuthApi, UserApi } from '../../services/api';
import cache, { keys } from '../../services/cache';
import router from '../../router';

let authApi = new AuthApi();
let userApi = new UserApi();

const state = {
	auth: false,
  user: null,
  token: null,
  refreshToken: null,
  firstTime: false
};

const getters = {
    checkLogin: state => state.auth,
    authHeader: state => state.auth ? { 'Authorization': 'Bearer ' + state.token } : {},
    refreshToken: state => state.refreshToken,
    user: state => state.user,
    firstTime: state => state.firstTime
};


function onLoggin (store, data) {
    store.commit(types.AUTH_SET_TOKEN, data.access_token);
    store.commit(types.AUTH_SET_REFRESH_TOKEN, data.refresh_token);
    fetchUser(store);
}

function login (store, { username, password }) {
    let creds = {};
    creds.username = username;
    creds.password = password;
    return authApi.login(creds).then((response) => {
        onLoggin(store, response.data);
        return Promise.resolve();
    }, ({ data }) => {
        return Promise.reject(data);
    });
}

function fetchUser (store) {
    return userApi.show().then((response) => {
        store.commit(types.AUTH_SET_USER, response.data);
    }).catch(({ data, status }) => {
        console.log(data, status);
    });
}

function retoken (store) {
    let params = {
        'refresh_token': store.getters.refreshToken
    };
    return new Promise((resolve) => {
        authApi.retoken(params).then((response) => {
            console.log('retoken response', response);
            store.commit(types.AUTH_SET_TOKEN, response.data.access_token);
            store.commit(types.AUTH_SET_REFRESH_TOKEN, response.data.refresh_token);
            console.log(state.refreshToken);
            resolve();
        }).catch(({ data, status }) => {
            // check for internet problems -> not resolve until retoken finish
            console.log(data, status);
            store.commit(types.AUTH_LOGOUT);
            router.push({ name: 'login' });
            resolve();
        });
    });
}

function logout (store) {
    store.commit(types.AUTH_LOGOUT);
    router.replace({ name: 'login' });
}

const actions = {
    fetchUser,
    login,
    logout,
    onLoggin,
    retoken
};

const mutations = {
    [types.AUTH_SET_TOKEN] (state, token) {
        state.token = token.replace('"', '');
        state.auth = true;
        cache.setItem(keys.TOKEN_KEY, token);
    },
    [types.AUTH_SET_REFRESH_TOKEN] (state, token) {
        state.refreshToken = token.replace('"', '');
        cache.setItem(keys.REFRESH_TOKEN_KEY, token);
    },
    [types.AUTH_SET_USER] (state, user) {
        state.user = user;
        cache.setItem(keys.USER_KEY, user);
    },
    [types.AUTH_LOGOUT] (state) {
        state.token = null;
        state.user = null;
        state.auth = false;
        cache.clear();
    },
    [types.AUTH_FIRST_TIME] (state, firstTime) {
        state.firstTime = firstTime;
    }

};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
