import Vue from 'vue'
import Vuex from 'vuex'
import * as actions from './actions';
import auth from './modules/auth';
import contract from './modules/contract';
import department from './modules/department';
import equipment from './modules/equipment';
import equipmentTemplate from './modules/equipmentTemplate';
import feeRule from './modules/feeRule';
import homeDoctor from './modules/homeDoctor';
import healthReport from './modules/healthReport';
import healthData from './modules/healthData';
import monitorScreen from './modules/monitorScreen';
import patient from './modules/patient';
import paramInfo from './modules/paramInfo';
import region from './modules/region';
import role from './modules/role';
import smsCaptcha from './modules/smsCaptcha';
import sosAlarm from './modules/sosAlarm';
import temperatureMonitor from './modules/temperatureMonitor';
import user from './modules/user';

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions,
  modules: {
    auth,
    contract,
    department,
    equipment,
    equipmentTemplate,
    feeRule,
    homeDoctor,
    healthReport,
    healthData,
    monitorScreen,
    patient,
    paramInfo,
    region,
    role,
    smsCaptcha,
    sosAlarm,
    temperatureMonitor,
    user
  }
})
