import * as types from '../mutation-types';
import { RegionApi } from '../../services/api';

let regionApi = new RegionApi();

const state = {
    regions: []
};

const getters = {
    regions: state => state.regions
};

const actions = {
  queryAll(store, params) {
    return new Promise((resolve, reject) => {
      regionApi.queryAll(params).then(response => {
        store.commit(types.REGIONS_SET, response.data)
        resolve()
      }).catch(error => {
        reject(error)
      })
    })
  }
};

const mutations = {
    [types.REGIONS_SET] (state, items) {
        state.regions = items;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
