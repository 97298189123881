import axios from 'axios'
import store from '@/store'
// import miniToastr from 'mini-toastr'

// miniToastr.init()

// create an axios instance
const service = axios.create({
  baseURL: process.env.BASE_API, // api 的 base_url
  timeout: 60000 // request timeout
})

// request interceptor
service.interceptors.request.use(
  config => {
    // Do something before request is sent
    if (store.getters['auth/authHeader']) {
      let authHeader = store.getters['auth/authHeader'];
      Object.assign(config.headers, authHeader);
    }
    return config
  },
  error => {
    // Do something with request error
    console.log(error) // for debug
    Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  response => response,
  error => {
    console.log( 'err ' + error) // for debug
    const data = error.response.data
    const status = error.response.status
    if (status === 401 && data && data.code && data.code === 40101) {
      store.dispatch('auth/retoken')
    }
    return Promise.reject(error.response)
  }
)

export default service
