import request from '../network'

class HomeDoctorApi {

  query (params, data) {
    return request({
      url: '/api/HomeDoctor/query',
      method: 'post',
      params,
      data
    })
  }

  queryAll () {
    return request({
      url: '/api/HomeDoctor/queryAll',
      method: 'get'
    })
  }

  create (data = {}) {
    return request({
      url: '/api/HomeDoctor',
      method: 'post',
      data
    })
  }

  update (data = {}) {
    return request({
      url: '/api/HomeDoctor/' + data.id,
      method: 'put',
      data
    })
  }

  delete (data = {}) {
    return request({
      url: '/api/HomeDoctor/' + data.id,
      method: 'delete'
    })
  }

  show (id) {
    return request({
      url: '/api/HomeDoctor/' + id,
      method: 'get'
    })
  }

}


export { HomeDoctorApi as default };
