import * as types from '../mutation-types';
import { RoleApi } from '../../services/api';

let roleApi = new RoleApi();

const state = {
    roles: []
};

const getters = {
    roles: state => state.roles
};

const actions = {
  query(store, { params, data }) {
    return new Promise((resolve, reject) => {
      roleApi.query(params, data).then(response => {
        store.commit(types.ROLES_SET, response.data.content)
        resolve(response.data)
      }).catch(error => {
        reject(error)
      })
    })
  },

  create (store, data) {
    return roleApi.create(data).then(response => {
      store.commit(types.ROLES_ADD, response.data);
      return Promise.resolve(response.data);
    }).catch(err => {
      if (err) {
        return Promise.reject(err);
      }
    });
  },

  update (store, data) {
    return roleApi.update(data).then(response => {
      store.commit(types.ROLES_UPDATE, response.data);
      return Promise.resolve(response.data);
    }).catch(err => {
      if (err) {
        return Promise.reject(err);
      }
    });
  },

  delete (store, data = {}) {
    return roleApi.delete(data).then( () => {
      store.commit(types.ROLES_DELETE, data);
      return Promise.resolve();
    }).catch(err => {
      if (err) {
        return Promise.reject(err);
      }
    });
  }
};



const mutations = {
    [types.ROLES_ADD] (state, role) {
        if (!state.roles) {
            state.roles = [];
        }
        state.roles.push(role);
    },
    [types.ROLES_SET] (state, role) {
        state.roles = role;
    },
    [types.ROLES_UPDATE] (state, role) {
        for (let i = 0; i < state.roles.length; i++) {
            if (state.roles[i].id === role.id) {
                state.roles[i] = role;
                state.roles.splice(i, 1, role)
            }
        }
    },
    [types.ROLES_DELETE] (state, role) {
        state.roles = state.roles.filter(item => item.id !== role.id);
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
