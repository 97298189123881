import * as types from '../mutation-types';
import { ParamInfoApi } from '../../services/api';

let paramInfoApi = new ParamInfoApi();

const state = {
    paramInfos: []
};

const getters = {
    paramInfos: state => state.paramInfos
};

const actions = {
  queryAllByTypeCode(store, typeCode) {
    return new Promise((resolve, reject) => {
      paramInfoApi.queryAllByTypeCode(typeCode).then(response => {
        store.commit(types.PARAMINFOS_SET, response.data)
        resolve()
      }).catch(error => {
        reject(error)
      })
    })
  }
};

const mutations = {
    [types.PARAMINFOS_SET] (state, items) {
        state.paramInfos = items;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
