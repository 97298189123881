import request from '../network'

class MonitorScreenApi {

  queryMonitorRealtimeData () {
    return request({
      url:'/api/MonitorScreen/queryMonitorRealtimeData',
      method: 'get'
    })
  }

  queryMonitorSummaryLevel1 () {
    return request({
      url:'/api/MonitorScreen/queryMonitorSummaryLevel1',
      method: 'get'
    })
  }

  queryMonitorSummaryLevel2 () {
    return request({
      url:'/api/MonitorScreen/queryMonitorSummaryLevel2',
      method: 'get'
    })
  }

}


export { MonitorScreenApi as default };
