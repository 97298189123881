import request from '../network'
import qs from 'qs'

class PatientApi {

  query (params, data) {
    return request({
      url: '/api/Patient/query',
      method: 'post',
      params,
      data,
      paramsSerializer: params => {
        return qs.stringify(params, {arrayFormat: 'repeat'})
      }
    })
  }

  create (data = {}) {
    return request({
      url: '/api/Patient/create',
      method: 'post',
      data
    })
  }

  update (data = {}) {
    return request({
      url: '/api/Patient/' + data.id,
      method: 'put',
      data
    })
  }

  delete (data = {}) {
    return request({
      url: '/api/Patient/' + data.id,
      method: 'delete',
      data
    })
  }

  show (id) {
    if (!id) {
        id = 'self';
    }
    return request({
      url: '/api/Patient/show/' + id,
      method: 'get'
    })
  }

  importCreate (params, data) {
    return request({
      url: '/api/Patient/import/create',
      method: 'post',
      params,
      data
    })
  }

  downloadPatientGrantImportTemplate  () {
    return request({
      url: '/api/Patient/download/patientGrantImportTemplate',
      method: 'get',
      responseType: 'blob'
    })
  }

  downloadPatientImportTemplate () {
    return request({
      url: '/api/Patient/download/patientImportTemplate',
      method: 'get',
      responseType: 'blob'
    })
  }

  signedCreate (data = {}) {
    return request({
      url: '/api/Patient/signedCreate',
      method: 'post',
      data
    })
  }

  importSign  (params, data) {
    return request({
      url: '/api/Patient/import/signed',
      method: 'post',
      params,
      data
    })
  }

  sign  (id, data) {
    return request({
      url: '/api/Patient/signed/' + id,
      method: 'post',
      data
    })
  }

}


export { PatientApi as default };
