import en from 'vuetify/es5/locale/en'

export default {
  label: 'English',
  title: 'CDMS',

  login: {
    label: 'Login',
    title: 'Doctor Chip',
    username: 'Username',
    password: 'Password',
    agreeTo: 'Agree To',
    userAgreement: 'User Agreement',
    readAndAgree: 'Please read and agree to policies'
  },

  profileMenus: {
    profile: 'Profile',
    settings: 'Settings',
    logout: 'Logout',
    resetPassword: 'Reset Password'
  },

  route: {
    root: 'root',
    deault: 'deault',
    login: 'Login',
    home: 'Home',
    about: 'About',
    helloWorld: 'Hello World',
    dashboard: 'Dashboard',
    people: 'People',
    system: 'System',
    systemUser: 'User',
    systemUserCreate: 'User Create',
    systemUserEdit: 'User Edit',
    systemDepartment: 'Department',
    systemRole: 'Role',
    equipment: 'Equipment',
    equipmentList: 'Equipment List',
    equipmentDistribute: 'Equipment Distribute',
    equipmentChange: 'Equipment Change',
    equipmentCreate: 'Equipment Create',
    equipmentShow: 'Equipment Show',
    equipmentTemplate: 'Equipment Template',
    equipmentTemplateCreate: 'Create Equipment Template',
    equipmentTemplateEdit: 'Edit Equipment Template',
    patient: 'Patient',
    patientList: 'Patient List',
    patientCreate: 'Patient Create',
    patientEdit: 'Patient Edit',
    patientShow: 'Patient Detail',
    patientSign: 'Patient Sign',
    patientHealthReports: "Health Report",
    patientHealthDatas: "Health Data",
    homeDoctor: 'Home Doctor',
    homeDoctorList: 'Home Doctor List',
    homeDoctorCreate: 'Home Doctor Create',
    homeDoctorEdit: 'Home Doctor Edit',
    healthReportShow: "Health Report Detail",
    contract: 'Contract',
    contractList: 'Contract List',
    contractCreate: 'Contract Create',
    contractEdit: 'Contract Edit',
    feeRule: 'Fee Rule',
    feeRuleList: 'Fee Rule List',
    feeRuleCreate: 'Fee Rule Create',
    feeRuleEdit: 'Fee Rule Edit',
    callCenter: "Call Center",
    callCenterList: "Call List",
    callCenterMap: "Call Map",
    callCenterShow: "Call Detail",
    temperature: 'Temperature',
    temperatureDashboard: 'Dashboard',
    temperatureDetail: 'Detail',
    forbidden: 'Forbidden',
    notFound: 'Not Found',
    serverError: 'Server Error',
    resetPassword: 'Reset Password'
  },

  system: {
    department: 'Department',
    region: 'Region'
  },

  department: {
    label : 'Department',
    list: 'Department List',
    depNo: 'No.',
    parent: 'Parent',
    name: 'Name',
    level: 'Level',
    description: 'Description',
    changeAdmin: 'Change Admin',
    addAdminUser: 'Add Administrator'
  },

  patient: {
    label: 'Patient',
    list: 'List',
    name: 'Name',
    diseases: 'Diseases',
    national: 'National',
    disabilityStatus: 'Disability Status',
    institutionAndDoctor: 'Institution And Doctor',
    createSign: 'Create And Sign',
    batchCreateSign: 'Batch Create And Sign',
    sign: 'Sign',
    informtion: 'Patient informtion',
    institution: 'Sign Institution',
    address: 'Home Address',
    doctor: 'Doctor',
    downloadTip: 'Import patients in bulk and upload excel templates if you don\'t. '
  },

  user: {
    label : 'User',
    list: 'User List',
    dep: 'Department',
    realName: 'Name',
    username: 'Username',
    address: 'Address',
    birthday: 'Birthday',
    homePhone: 'HomePhone',
    phone: 'Phone',
    email: 'Email',
    credentialNum: 'Credential Num.',
    isMonitorScreenUser: 'Monitor Screen'
  },

  role: {
    label: 'Role',
    list: 'Role List',
    roleName: 'Role Name',
    showName: 'Show Name'
  },

  equipment: {
    label: 'Equipment',
    list: 'Equipment List',
    emId: 'ID',
    purchaseDate: 'Purchase Date',
    storeDate: 'Store Date',
    proNumber: 'Product No.',
    deviceId: 'Device Id',
    state: 'State',
    distribute: 'Distribute',
    scanCodeConfirm: 'Scan Code Confirm',
    store: 'Store',
    batchImport: 'Batch Import',
    uploadList: 'Upload Equipment List',
    confirmDistribute: 'Confirm Distribute',
    chooseEquipmentTemplate: 'Choose Equipment Template',
    changeEquipment: 'Change Equipment',
    downloadTip: 'Import equipment in bulk and upload excel templates if you don\'t. '
  },

  equipmentTemplate: {
    label: 'Equipment Template',
    list: 'Equipment Template List',
    company: 'Company',
    companyAddr: 'Company Addr.',
    companyTel: 'Company Tel.',
    deposit: 'Deposit',
    name: 'Name',
    no: 'No.',
    price: 'Price',
    recst: 'Recst',
    regid: 'Register ID',
    rental: 'Rental',
    serviceDate: 'Service Date',
    type: 'Type',
    create: 'Create Template',
    category: 'Equipment Category'
  },

  equipmentCategory: {
    leasing: 'Leasing' ,
    sale: 'Sale',
    leasingAndSale: 'Leasing And Sale'
  },

  equipmentState: {
    ready: 'Ready' ,
    free: 'Free',
    grant: 'Grant',
    lost: 'Lost',
    damaged: 'Damaged'
  },

  contract: {
    label: 'Contract',
    list: 'Contract List',
    contractName: 'Contract Name',
    serviceType: 'Services',
    serviceItem: 'Service Items',
    operator: 'created By'
  },

  feeRule: {
    label: 'Fee Rule',
    list: 'Fee Rule List',
    feeFrequency: 'Fee Frequency',
    feeType: 'Fee Type',
    feeValue: 'Fee',
    ruleName: 'Rule Name',
    ruleType: 'Rule Type',
    operator: 'created By'
  },

  ruleType: {
    standard: 'Standard',
    expert: 'Expert'
  },

  homeDoctor: {
    label: 'Home Doctor',
    list: 'Home Doctor List'
  },

  sosAlarm: {
    label: 'SOS Alarm',
    list: 'SOS Alarm List',
    address: 'SOS Address',
    alarmTime: 'SOS Time',
    status: 'Status',
    doctorName: 'Doctor Name',
    progress: 'Progress',
    show: 'Show',
    progressContent: 'Progress Content',
    age: 'Age',
    ages: 'Ages',
    patientInfo: 'Patient Informtion',
    callInfo: 'Call Informtion',
    progressInfo: 'Progress Informtion',
    progressOperator: 'Progress Operator',
    progressTime: 'Progress Time',
    progressType: 'Progress Type'
  },

  healthReport: {
    label: 'Health Report',
    list: 'Health Report List',
    reportName: 'Report Name',
    reportStatus: 'Report Status',
    startDate: 'Start Date',
    endDate: 'End Date',
    createdAt: 'Created At',
    frequency: 'Frequency',
    healthReportDates: 'Report Dates',
    analysisResult: 'Analysis Result',
    heartRate: 'Heart Rate',
    bloodOxygen: 'Blood Oxygen',
    sport: 'Sport',
    bloodPressure: 'Blood Pressure',
    time: 'Test Time',
    systolic: 'Systolic',
    diastolic: 'Diastolic',
    conclusion: 'Conclusion',
    bloodOxygenLabel: 'blood Oxygen',
    timesPerMinute: 'times/Minute',
    steps: 'Steps'
  },

  monitorScreen: {
    summaryLevelTitle1: 'All Province Medical Data Statistics',
    summaryLevelTitle2: 'All City Medical Data Statistics',
    sosDataTitle: 'SOS Sumary',
    sosDataTotalNum: 'Total Num',
    sosDataTodayNum: 'Today Num',
    sosDataWeekNum: 'Week Num',
    sosDataMonthNum: 'Month Num'
  },

  progressType: {
    userMisreport: 'User Misreport',
    telephoneGuidance: 'Telephone Guidance',
    visitRescue: 'Visit Rescue',
    other: 'Other'
  },

  sosAlarmStatus: {
    ready: 'Ready',
    finished: 'Finished'
  },

  serviceType: {
    reportService: 'Report Service',
    sosAlarm: 'SOS Alarm',
    healthConsultation: 'Health Consultation'
  },

  serviceItem: {
    healthReport: 'Health Report',
    ecgReport: 'ECG Report',
    ecgStandard: 'ECG Standard',
    ecgExpert: 'ECG Expert'
  },

  serviceFrequency: {
    dayFrequency: '1 Day 1 Time',
    weekFrequency: '1 Week 1 Time',
    monthFrequency: '1 Month 1 Time',
    quarterFrequency: '1 Quarter 1 Time',
    seriesFrequency: 'Series',
    combinationPackage: 'Combination Package'
  },

  disabilityStatus: {
    disability: 'DISABILITY',
    halfDisability: 'HALF_DISABILITY',
    noDisability: 'NO_DISABILITY'
  },

  signStatus: {
    unsigned: 'Unsigned',
    signed: 'Signed',
  },

  temperature: {
    dashboardTotalRecords: 'Total Records',
    dashboardWarnRecords: 'Warn Records',
    dashboardTotalPersons: 'Total Persons',
    dashboardWarnPersons: 'Warn Persons',
    dashboardWarnRecordsTendency: 'Warn Records Tendency',
    dashboardWarnRecordsRatio: 'Warn Records Ratio',
    dashboardWarnPersonsTendency: 'Warn Persons Tendency',
    dashboardWarnPersonsRatio: 'Warn Persons Ratio',
    dashboardDataList: 'Data List',
    dashboardDataMap: 'Data Map',
    dashboardRegion: 'Region',
    detailAge: 'Age',
    detailTime: 'Time',
    detailTemperature: 'Temperature',
    detailInstitution: 'Institution'
  },

  region: {
    label: 'Region',
    province: 'Province',
    city: 'City',
    county: 'County',
    town:'Town',
    village: 'Village'
  },

  gender: {
    male: 'MALE',
    female: 'FEMALE',
    unknown: 'UNKNOWN'
  },

  common: {
    actions: 'Actions',
    submit: 'Submit',
    create: 'Create',
    cancel: 'Cancel',
    name: 'Name',
    gender: 'Gender',
    age: 'Age',
    address: 'Address',
    birthday: 'Birthday',
    description: 'Description',
    password: 'Password',
    confirmPassword: 'Confirm Password',
    close: 'Close',
    delete: 'Delete',
    distribute: 'Distribute',
    goBack: 'Go back',
    search: 'Search',
    yuan: 'Yuan',
    time: 'Time',
    week: 'Week',
    month: 'Month',
    warning: 'Warning',
    ok: 'OK',
    phone: 'Phone',
    chooseFiles: 'Choose Files',
    clickDownload: 'Click DownLoad',
    belongToArea: 'Belong To Area',
    batchCreate: 'Batch Create',
    identityCard: 'ID',
    idAddress: 'ID Address',
    save: 'Save',
    createdAt: 'Created At',
    startDate: 'Start Date',
    endDate: 'End Date',
    to: 'To',
    noData: 'No Data',
    max: 'Max.',
    min: 'Min.',
    highest: 'Highest',
    lowest: 'Lowest',
    high: 'High',
    low: 'Low',
    avg: 'Avg.',
    sum: 'Sum.',
    verificationCode: 'Verification Code',
    sendVerificationCode: 'Send Verification Code',
    changeSuccess: 'Change Success',
    historyData: 'History',
    detailDatas: 'Detail Datas',
    true: 'True',
    false: 'False'
  },

  rule: {
    required: '{0} is required',
    selected: '{0} need selected',
    sameAs: 'same sa {0}',
    format: '{0} is format error',
    mustIsExcel: 'Must is Excel!'
  },

  message: {
    deleteConfirm: 'Delete Confirm ',
    recyclingConfirm: 'Recycling Confirm ',
    addMonitorScreenUserConfirm: 'add {0} For Monitor Screen User ',
    delMonitorScreenUserConfirm: 'delete {0} For Monitor Screen User  ',
    addEpidemicMonitorUserConfirm: 'add {0} For Epidemic Monitor User ',
    delEpidemicMonitorUserConfirm: 'delete {0} For Epidemic Monitor User  ',
    needSelectedOne: 'Need Selected One',
    systemErr: 'System Error, Please try again!',
    resourceExistedErr: '{0}:{1} is already exists, Please re-enter it!',
    notContractedErr: 'The patient is not contracted!',
    usernameOrPasswordErr: 'Username Or Password Error!',
    importTooltips: 'Success: {0} Items, Fail: {1} Items！',
    readAndAgree: 'Please read and agree'
  },

  ...en
}
