import Vue from 'vue'
import ECharts from 'vue-echarts' // 在 webpack 环境下指向 components/ECharts.vue

import hebeisheng from '../components/maps/130000.json'
import shijiazhuangshi from '../components/maps/130100.json'
import tangshanshi from '../components/maps/130200.json'
import qinhuangdaoshi from '../components/maps/130300.json'
import handanshi from '../components/maps/130400.json'
import xingtaishi from '../components/maps/130500.json'
import baodingshi from '../components/maps/130600.json'
import zhangjiakoushi from '../components/maps/130700.json'
import chengdeshi from '../components/maps/130800.json'
import cangzhoushi from '../components/maps/130900.json'
import langfangshi from '../components/maps/131000.json'
import hengshuishi from '../components/maps/131100.json'

import anhuisheng from '../components/maps/340000.json'
import hefeishi from '../components/maps/340100.json'
import wuhushi from '../components/maps/340200.json'
import fengbushi from '../components/maps/340300.json'
import huainanshi from '../components/maps/340400.json'
import maanshanshi from '../components/maps/340500.json'
import huaibeishi from '../components/maps/340600.json'
import tonglingshi from '../components/maps/340700.json'
import anqingshi from '../components/maps/340800.json'
import huangshanshi from '../components/maps/341000.json'
import xuzhoushi from '../components/maps/341100.json'
import fuyangshi from '../components/maps/341200.json'
import suzhoushi from '../components/maps/341300.json'
import liuanshi from '../components/maps/341500.json'
import haozhoushi from '../components/maps/341600.json'
import chizhoushi from '../components/maps/341700.json'
import xuanchengshi from '../components/maps/341800.json'

ECharts.registerMap('130000', hebeisheng)
ECharts.registerMap('130100', shijiazhuangshi)
ECharts.registerMap('130200', tangshanshi)
ECharts.registerMap('130300', qinhuangdaoshi)
ECharts.registerMap('130400', handanshi)
ECharts.registerMap('130500', xingtaishi)
ECharts.registerMap('130600', baodingshi)
ECharts.registerMap('130700', zhangjiakoushi)
ECharts.registerMap('130800', chengdeshi)
ECharts.registerMap('130900', cangzhoushi)
ECharts.registerMap('131000', langfangshi)
ECharts.registerMap('131100', hengshuishi)

ECharts.registerMap('340000', anhuisheng)
ECharts.registerMap('340100', hefeishi)
ECharts.registerMap('340200', wuhushi)
ECharts.registerMap('340300', fengbushi)
ECharts.registerMap('340400', huainanshi)
ECharts.registerMap('340500', maanshanshi)
ECharts.registerMap('340600', huaibeishi)
ECharts.registerMap('340700', tonglingshi)
ECharts.registerMap('340800', anqingshi)
ECharts.registerMap('341000', huangshanshi)
ECharts.registerMap('341100', xuzhoushi)
ECharts.registerMap('341200', fuyangshi)
ECharts.registerMap('341300', suzhoushi)
ECharts.registerMap('341500', liuanshi)
ECharts.registerMap('341600', haozhoushi)
ECharts.registerMap('341700', chizhoushi)
ECharts.registerMap('341800', xuanchengshi)

Vue.component('v-chart', ECharts)
