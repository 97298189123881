import LocalStorage from './LocalStorage';

export default new LocalStorage();

export const keys = {
    'TOKEN_KEY': 'TOKEN',
    'USER_KEY': 'USER',
    'FIRST_TIME_APP_KEY': 'FIRST_TIME_APP',
    'REFRESH_TOKEN_KEY': 'REFRESH_TOKEN',
};
