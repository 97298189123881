import request from '../network'

class RegionApi {

  queryAll (params) {
    return request({
      url: '/api/CnRegions/queryAll',
      method: 'get',
      params
    })
  }

}


export { RegionApi as default };
