import request from '../network'

class RoleApi {

  query (params, data) {
    return request({
      url: '/api/Roles/query',
      method: 'post',
      params,
      data
    })
  }

  create (data = {}) {
    return request({
      url: '/api/Roles',
      method: 'post',
      data
    })
  }

  update (data = {}) {
    return request({
      url: '/api/Roles/' + data.id,
      method: 'put',
      data
    })
  }

  delete (data = {}) {
    return request({
      url: '/api/Roles/' + data.id,
      method: 'delete',
      data
    })
  }
}


export { RoleApi as default };
