import * as types from '../mutation-types';
import { EquipmentTemplateApi } from '../../services/api';

let equipmentTemplateApi = new EquipmentTemplateApi();

const state = {
    currentEquipmentTemplate: null,
    equipmentTemplates: []
};

const getters = {
  currentEquipmentTemplate: state => state.currentEquipmentTemplate,
  equipmentTemplates: state => state.equipmentTemplates
};

const actions = {
  query(store, { params, data }) {
    return new Promise((resolve, reject) => {
      equipmentTemplateApi.query(params, data).then(response => {
        store.commit(types.EQUIPMENT_TEMPLATES_SET, response.data.content)
        resolve(response.data)
      }).catch(error => {
        reject(error)
      })
    })
  },

  queryAll(store) {
    return new Promise((resolve, reject) => {
      equipmentTemplateApi.queryAll().then(response => {
        store.commit(types.EQUIPMENT_TEMPLATES_SET, response.data)
        resolve(response.data)
      }).catch(error => {
        reject(error)
      })
    })
  },

  create (store, data) {
    return equipmentTemplateApi.create(data).then(response => {
      store.commit(types.EQUIPMENT_TEMPLATES_ADD, response.data);
      return Promise.resolve(response.data);
    }).catch(err => {
      if (err) {
        return Promise.reject(err);
      }
    });
  },

  update (store, data) {
    return equipmentTemplateApi.update(data).then(response => {
      store.commit(types.EQUIPMENT_TEMPLATES_UPDATE, response.data);
      return Promise.resolve(response.data);
    }).catch(err => {
      if (err) {
        return Promise.reject(err);
      }
    });
  },

  delete (store, data = {}) {
    return equipmentTemplateApi.delete(data).then( () => {
      store.commit(types.EQUIPMENT_TEMPLATES_DELETE, data);
      return Promise.resolve();
    }).catch(err => {
      if (err) {
        return Promise.reject(err);
      }
    });
  },

  show (store, id = null) {
    return equipmentTemplateApi.show(id).then( response => {
      store.commit(types.EQUIPMENT_TEMPLATES_SET_CURRENT, response.data);
      return Promise.resolve();
    }).catch(err => {
      if (err) {
        return Promise.reject(err);
      }
    });
  },

  queryAllByDepId (store, depId = null) {
    return equipmentTemplateApi.queryAllByDepId(depId).then( response => {
      store.commit(types.EQUIPMENT_TEMPLATES_SET, response.data);
      return Promise.resolve();
    }).catch(err => {
      if (err) {
        return Promise.reject(err);
      }
    });
  }
};



const mutations = {
  [types.EQUIPMENT_TEMPLATES_SET_CURRENT] (state, equipmentTemplate) {
      state.currentEquipmentTemplate = equipmentTemplate;
  },
  [types.EQUIPMENT_TEMPLATES_ADD] (state, equipmentTemplate) {
      if (!state.equipmentTemplates) {
          state.equipmentTemplates = [];
      }
      state.equipmentTemplates.push(equipmentTemplate);
  },
  [types.EQUIPMENT_TEMPLATES_SET] (state, items) {
      state.equipmentTemplates = items;
  },
  [types.EQUIPMENT_TEMPLATES_UPDATE] (state, equipmentTemplate) {
      for (let i = 0; i < state.equipmentTemplates.length; i++) {
          if (state.equipmentTemplates[i].id === equipmentTemplate.id) {
              state.equipmentTemplates.splice(i, 1, equipmentTemplate)
          }
      }
  },
  [types.EQUIPMENT_TEMPLATES_DELETE] (state, equipmentTemplate) {
      state.equipmentTemplates = state.equipmentTemplates.filter(item => item.id !== equipmentTemplate.id);
  }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
