import request from '../network'

class SosAlarmApi {

  query (params, data) {
    return request({
      url: '/api/SosAlarm/query',
      method: 'post',
      params,
      data
    })
  }

  queryAllReadySosAlarm () {
    return request({
      url: '/api/SosAlarm/queryAllReadySosAlarm',
      method: 'get'
    })
  }

  queryAllSosAlarm (params) {
    return request({
      url: '/api/SosAlarm/queryAllSosAlarm',
      method: 'post',
      params
    })
  }

  progress (id, data = {}) {
    return request({
      url: '/api/SosAlarm/progress/' + id,
      method: 'post',
      data
    })
  }

  show (id) {
    return request({
      url: '/api/SosAlarm/' + id,
      method: 'get'
    })
  }
}


export { SosAlarmApi as default };
