export const AUTH_SET_TOKEN = 'AUTH_SET_TOKEN';
export const AUTH_SET_USER = 'AUTH_SET_USER';
export const AUTH_SET_REFRESH_TOKEN = 'AUTH_SET_REFRESH_TOKEN';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const AUTH_FIRST_TIME = 'AUTH_FIRST_TIME';

export const DEPARTMENTS_SET = 'DEPARTMENTS_SET';
export const DEPARTMENTS_ADD = 'DEPARTMENTS_ADD';
export const DEPARTMENTS_UPDATE = 'DEPARTMENTS_UPDATE';
export const DEPARTMENTS_DELETE = 'DEPARTMENTS_DELETE';
export const DEPARTMENTS_RESET_ADMIN = 'DEPARTMENTS_RESET_ADMIN';

export const EQUIPMENTS_SET = 'EQUIPMENTS_SET';
export const EQUIPMENTS_ADD = 'EQUIPMENTS_ADD';
export const EQUIPMENTS_UPDATE = 'EQUIPMENTS_UPDATE';
export const EQUIPMENTS_DELETE = 'EQUIPMENTS_DELETE';
export const EQUIPMENTS_RECYCLING = 'EQUIPMENTS_RECYCLING';
export const EQUIPMENTS_SET_CURRENT = 'EQUIPMENTS_SET_CURRENT';

export const EQUIPMENT_TEMPLATES_SET = 'EQUIPMENT_TEMPLATES_SET';
export const EQUIPMENT_TEMPLATES_ADD = 'EQUIPMENT_TEMPLATES_ADD';
export const EQUIPMENT_TEMPLATES_UPDATE = 'EQUIPMENT_TEMPLATES_UPDATE';
export const EQUIPMENT_TEMPLATES_DELETE = 'EQUIPMENT_TEMPLATES_DELETE';
export const EQUIPMENT_TEMPLATES_SET_CURRENT = 'EQUIPMENT_TEMPLATES_SET_CURRENT';

export const HOMEDOCTORS_SET = 'HOMEDOCTORS_SET';
export const HOMEDOCTORS_ADD = 'HOMEDOCTORS_ADD';
export const HOMEDOCTORS_UPDATE = 'HOMEDOCTORS_UPDATE';
export const HOMEDOCTORS_DELETE = 'HOMEDOCTORS_DELETE';
export const HOMEDOCTORS_SET_CURRENT = 'HOMEDOCTORS_SET_CURRENT';

export const HEALTHREPORTS_SET = 'HEALTHREPORTS_SET';
export const HEALTHREPORTS_ADD = 'HEALTHREPORTS_ADD';
export const HEALTHREPORTS_UPDATE = 'HEALTHREPORTS_UPDATE';
export const HEALTHREPORTS_SET_CURRENT = 'HEALTHREPORTS_SET_CURRENT';

export const HEALTHDATA_BLOODOXYGENS_SET_CURRENT = 'HEALTHDATA_BLOODOXYGENS_SET_CURRENT';
export const HEALTHDATA_BLOODPRESSURES_SET_CURRENT = 'HEALTHDATA_BLOODPRESSURES_SET_CURRENT';
export const HEALTHDATA_HEARTRATES_SET_CURRENT = 'HEALTHDATA_HEARTRATES_SET_CURRENT';
export const HEALTHDATA_SPORTS_SET_CURRENT = 'HEALTHDATA_SPORTS_SET_CURRENT';

export const MONITORSCREEN_REALTIMEDATAS_SET_CURRENT = 'MONITORSCREEN_REALTIMEDATAS_SET_CURRENT';
export const MONITORSCREEN_SUMMARYLEVEL1_SET_CURRENT = 'MONITORSCREEN_SUMMARYLEVEL1_SET_CURRENT';
export const MONITORSCREEN_SUMMARYLEVEL2_SET_CURRENT = 'MONITORSCREEN_SUMMARYLEVEL2_SET_CURRENT'

export const CONTRACTS_SET = 'CONTRACTS_SET';
export const CONTRACTS_ADD = 'CONTRACTS_ADD';
export const CONTRACTS_UPDATE = 'CONTRACTS_UPDATE';
export const CONTRACTS_DELETE = 'CONTRACTS_DELETE';
export const CONTRACTS_SET_CURRENT = 'CONTRACTS_SET_CURRENT';

export const FEERULES_SET = 'FEERULES_SET';
export const FEERULES_ADD = 'FEERULES_ADD';
export const FEERULES_UPDATE = 'FEERULES_UPDATE';
export const FEERULES_DELETE = 'FEERULES_DELETE';
export const FEERULES_SET_CURRENT = 'FEERULES_SET_CURRENT';

export const USERS_SET = 'USERS_SET';
export const USERS_ADD = 'USERS_ADD';
export const USERS_UPDATE = 'USERS_UPDATE';
export const USERS_DELETE = 'USERS_DELETE';
export const USERS_SET_CURRENT = 'USERS_SET_CURRENT';

export const PATIENTS_SET = 'PATIENTS_SET';
export const PATIENTS_ADD = 'PATIENTS_ADD';
export const PATIENTS_UPDATE = 'PATIENTS_UPDATE';
export const PATIENTS_DELETE = 'PATIENTS_DELETE';
export const PATIENTS_SET_CURRENT = 'PATIENTS_SET_CURRENT';

export const PARAMINFOS_SET = 'PARAMINFOS_SET';

export const ROLES_SET = 'ROLES_SET';
export const ROLES_ADD = 'ROLES_ADD';
export const ROLES_UPDATE = 'ROLES_UPDATE';
export const ROLES_DELETE = 'ROLES_DELETE';

export const REGIONS_SET = 'REGIONS_SET';


export const SOSALARMS_SET = 'SOSALARMS_SET';
export const SOSALARMS_ADD = 'SOSALARMS_ADD';
export const SOSALARMS_UPDATE = 'SOSALARMS_UPDATE';
export const SOSALARMS_DELETE = 'SOSALARMS_DELETE';
export const SOSALARMS_SET_CURRENT = 'SOSALARMS_SET_CURRENT';

export const TEMPERATURE_DETAILSDATAS_SET = 'TEMPERATURE_DETAILSDATAS_SET';
export const TEMPERATURE_MONITORDATAS_SET = 'TEMPERATURE_MONITORDATAS_SET';
export const TEMPERATURE_SUMMARYDATAS_SET = 'TEMPERATURE_SUMMARYDATAS_SET';
export const TEMPERATURE_TRENDSDATAS_SET = 'TEMPERATURE_TRENDSDATAS_SET';

