import * as types from '../mutation-types';
import { PatientApi } from '../../services/api';

let patientApi = new PatientApi();

const state = {
    currentPatient: null,
    patients: []
};

const getters = {
  currentPatient: state => state.currentPatient,
  patients: state => state.patients
};

const actions = {
  query(store, { params, data }) {
    return new Promise((resolve, reject) => {
      patientApi.query(params, data).then(response => {
        store.commit(types.PATIENTS_SET, response.data.content)
        resolve(response.data)
      }).catch(error => {
        reject(error)
      })
    })
  },

  create (store, data) {
    return patientApi.create(data).then(response => {
      store.commit(types.PATIENTS_ADD, response.data);
      return Promise.resolve(response.data);
    }).catch(err => {
      if (err) {
        return Promise.reject(err);
      }
    });
  },

  update (store, data) {
    return patientApi.update(data).then(response => {
      store.commit(types.PATIENTS_UPDATE, response.data);
      return Promise.resolve(response.data);
    }).catch(err => {
      if (err) {
        return Promise.reject(err);
      }
    });
  },

  delete (store, data = {}) {
    return patientApi.delete(data).then( () => {
      store.commit(types.PATIENTS_DELETE, data);
      return Promise.resolve();
    }).catch(err => {
      if (err) {
        return Promise.reject(err);
      }
    });
  },

  show (store, id = null) {
    return patientApi.show(id).then( response => {
      store.commit(types.PATIENTS_SET_CURRENT, response.data);
      return Promise.resolve();
    }).catch(err => {
      if (err) {
        return Promise.reject(err);
      }
    });
  },

  queryAllByDepId (store, depId = null) {
    return patientApi.queryAllByDepId(depId).then( response => {
      store.commit(types.PATIENTS_SET, response.data);
      return Promise.resolve();
    }).catch(err => {
      if (err) {
        return Promise.reject(err);
      }
    });
  },

  downloadPatientGrantImportTemplate () {
    return patientApi.downloadPatientGrantImportTemplate().then( response => {
      return Promise.resolve(response);
    }).catch(err => {
      if (err) {
        return Promise.reject(err);
      }
    });
  },

  downloadPatientImportTemplate () {
    return patientApi.downloadPatientImportTemplate().then( response => {
      return Promise.resolve(response);
    }).catch(err => {
      if (err) {
        return Promise.reject(err);
      }
    });
  },

  importCreate (store, { params, data }) {
    return patientApi.importCreate(params, data).then( response => {
      return Promise.resolve(response);
    }).catch(err => {
      if (err) {
        return Promise.reject(err);
      }
    });
  },

  signedCreate (store, data) {
    return patientApi.signedCreate(data).then(response => {
      store.commit(types.PATIENTS_ADD, response.data);
      return Promise.resolve(response.data);
    }).catch(err => {
      if (err) {
        return Promise.reject(err);
      }
    });
  },

  importSign (store, { params, data }) {
    return patientApi.importSign(params, data).then( response => {
      return Promise.resolve(response);
    }).catch(err => {
      if (err) {
        return Promise.reject(err);
      }
    });
  },

  sign (store, { id, data }) {
    return patientApi.sign(id, data).then( response => {
      return Promise.resolve(response);
    }).catch(err => {
      if (err) {
        return Promise.reject(err);
      }
    });
  },


};



const mutations = {
  [types.PATIENTS_SET_CURRENT] (state, patient) {
      state.currentPatient = patient;
  },
  [types.PATIENTS_ADD] (state, patient) {
      if (!state.patients) {
          state.patients = [];
      }
      state.patients.push(patient);
  },
  [types.PATIENTS_SET] (state, items) {
      state.patients = items;
  },
  [types.PATIENTS_UPDATE] (state, patient) {
      for (let i = 0; i < state.patients.length; i++) {
          if (state.patients[i].id === patient.id) {
              state.patients.splice(i, 1, patient)
          }
      }
  },
  [types.PATIENTS_DELETE] (state, patient) {
      state.patients = state.patients.filter(item => item.id !== patient.id);
  }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
