import request from '../network'

class AuthApi {
  login(data) {
    return request({
      url: '/api/token/auth',
      method: 'post',
      data
    })
  }

  retoken(params, data) {
    return request({
      url: '/api/token/refresh',
      method: 'post',
      params,
      data
    })
  }
}

export { AuthApi as default };
