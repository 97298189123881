import * as types from '../mutation-types';
import { ContractApi } from '../../services/api';

let contractApi = new ContractApi();

const state = {
    currentContract: null,
    contracts: []
};

const getters = {
  currentContract: state => state.currentContract,
  contracts: state => state.contracts
};

const actions = {
  query(store, { params, data }) {
    return new Promise((resolve, reject) => {
      contractApi.query(params, data).then(response => {
        store.commit(types.CONTRACTS_SET, response.data.content)
        resolve(response.data)
      }).catch(error => {
        reject(error)
      })
    })
  },

  create (store, data) {
    return contractApi.create(data).then(response => {
      store.commit(types.CONTRACTS_ADD, response.data);
      return Promise.resolve(response.data);
    }).catch(err => {
      if (err) {
        return Promise.reject(err);
      }
    });
  },

  update (store, data) {
    return contractApi.update(data).then(response => {
      store.commit(types.CONTRACTS_UPDATE, response.data);
      return Promise.resolve(response.data);
    }).catch(err => {
      if (err) {
        return Promise.reject(err);
      }
    });
  },

  delete (store, data = {}) {
    return contractApi.delete(data).then( () => {
      store.commit(types.CONTRACTS_DELETE, data);
      return Promise.resolve();
    }).catch(err => {
      if (err) {
        return Promise.reject(err);
      }
    });
  },

  show (store, id = null) {
    return contractApi.show(id).then( response => {
      store.commit(types.CONTRACTS_SET_CURRENT, response.data);
      return Promise.resolve();
    }).catch(err => {
      if (err) {
        return Promise.reject(err);
      }
    });
  }

};



const mutations = {
  [types.CONTRACTS_SET_CURRENT] (state, contract) {
      state.currentContract = contract;
  },
  [types.CONTRACTS_ADD] (state, contract) {
      if (!state.contracts) {
          state.contracts = [];
      }
      state.contracts.push(contract);
  },
  [types.CONTRACTS_SET] (state, items) {
      state.contracts = items;
  },
  [types.CONTRACTS_UPDATE] (state, contract) {
      for (let i = 0; i < state.contracts.length; i++) {
          if (state.contracts[i].id === contract.id) {
              state.contracts.splice(i, 1, contract)
          }
      }
  },
  [types.CONTRACTS_DELETE] (state, contract) {
      state.contracts = state.contracts.filter(item => item.id !== contract.id);
  }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
