import * as types from '../mutation-types';
import { HealthReportApi } from '../../services/api';

let healthReportApi = new HealthReportApi();

const state = {
  currentHealthReport: null,
  healthReports: []
};

const getters = {
  currentHealthReport: state => state.currentHealthReport,
  healthReports: state => state.healthReports
};

const actions = {

  query(store, { params, data }) {
    return new Promise((resolve, reject) => {
      healthReportApi.query(params, data).then(response => {
        store.commit(types.HEALTHREPORTS_SET, response.data.content)
        resolve(response.data)
      }).catch(error => {
        reject(error)
      })
    })
  },

  queryAll(store) {
    return new Promise((resolve, reject) => {
      healthReportApi.queryAll().then(response => {
        store.commit(types.HEALTHREPORTS_SET, response.data)
        resolve()
      }).catch(error => {
        reject(error)
      })
    })
  },

  show (store, id = null) {
    return healthReportApi.show(id).then( response => {
      store.commit(types.HEALTHREPORTS_SET_CURRENT, response.data);
      return Promise.resolve();
    }).catch(err => {
      if (err) {
        return Promise.reject(err);
      }
    });
  }

};


const mutations = {

  [types.HEALTHREPORTS_SET_CURRENT] (state, report) {
      state.currentHealthReport = report;
  },

  [types.DEPARTMENTS_ADD] (state, report) {
    if (!state.healthReports) {
      state.healthReports = [];
    }
    state.healthReports.push(report);
  },

  [types.HEALTHREPORTS_SET] (state, items) {
    state.healthReports = items;
  },

  [types.HEALTHREPORTS_UPDATE] (state, report) {
    for (let i = 0; i < state.healthReports.length; i++) {
      if (state.healthReports[i].id === report.id) {
        state.healthReports[i] = report;
      }
    }
  }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
