import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import zhHans from '@/locale/zh-Hans'
import en from '@/locale/en'

Vue.use(Vuetify);

const theme = {
  primary: '#4CAF50',
  secondary: '#9C27b0',
  accent: '#9C27b0',
  info: '#00CAE3'
};

export default new Vuetify({
  lang: {
    locales: { zhHans, en },
    current: 'zhHans'
  },
  theme: {
    options: {
      customProperties: true
    },
    themes: {
      light: theme
    }
  }
})
