import request from '../network'

class DepartmentApi {

  query (params, data) {
    return request({
      url: '/api/Departments/query',
      method: 'post',
      params,
      data
    })
  }

  queryAll () {
    return request({
      url: '/api/Departments/queryAll',
      method: 'get'
    })
  }

  create (data = {}) {
    return request({
      url: '/api/Departments',
      method: 'post',
      data
    })
  }

  update (data = {}) {
    return request({
      url: '/api/Departments/' + data.id,
      method: 'put',
      data
    })
  }

  delete (data = {}) {
    return request({
      url: '/api/Departments/' + data.id,
      method: 'delete',
      data
    })
  }

  resetAdmin (id, params) {
    return request({
      url: '/api/Departments/actions/reset-admin/' + id,
      method: 'post',
      params
    })
  }
}


export { DepartmentApi as default };
