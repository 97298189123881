import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import vuetify from '@/plugins/vuetify'

import '@/plugins/moment'

import '@/plugins/dataView'

import '@/plugins/baiduMap'

import '@/plugins/echarts'

import '@/plugins/apexcharts'

import bus from './services/bus-event';

Vue.config.productionTip = false

store.dispatch('init');

bus.on('system-ready', () => {
  new Vue({
    router,
    store,
    vuetify,
    render: h => h(App)
  }).$mount('#app')
});
