import request from '../network'
import qs from 'qs'

class HealthDataApi {

  findBloodOxygenTrendDataByPatientIdAndRangeDate (params, data) {
    return request({
      url: '/api/IotHealthData/findBloodOxygenTrendDataByPatientIdAndRangeDate',
      method: 'post',
      params,
      data,
      paramsSerializer: params => {
        return qs.stringify(params, {arrayFormat: 'repeat'})
      }
    })
  }

  findBloodOxygenTrendDataByPatientIdAndTargetDate (params) {
    return request({
      url: '/api/IotHealthData/findBloodOxygenTrendDataByPatientIdAndTargetDate',
      method: 'get',
      params,
      paramsSerializer: params => {
        return qs.stringify(params, {arrayFormat: 'repeat'})
      }
    })
  }

  findBloodPressureTrendDataByPatientIdAndRangeDate (params, data) {
    return request({
      url: '/api/IotHealthData/findBloodPressureTrendDataByPatientIdAndRangeDate',
      method: 'post',
      params,
      data,
      paramsSerializer: params => {
        return qs.stringify(params, {arrayFormat: 'repeat'})
      }
    })
  }

  findBloodPressureTrendDataByPatientIdAndTargetDate (params) {
    return request({
      url: '/api/IotHealthData/findBloodPressureTrendDataByPatientIdAndTargetDate',
      method: 'get',
      params,
      paramsSerializer: params => {
        return qs.stringify(params, {arrayFormat: 'repeat'})
      }
    })
  }

  findHeartRateTrendDataByPatientIdAndRangeDate (params, data) {
    return request({
      url: '/api/IotHealthData/findHeartRateTrendDataByPatientIdAndRangeDate',
      method: 'post',
      params,
      data,
      paramsSerializer: params => {
        return qs.stringify(params, {arrayFormat: 'repeat'})
      }
    })
  }

  findHeartRateTrendDataByPatientIdAndTargetDate (params) {
    return request({
      url: '/api/IotHealthData/findHeartRateTrendDataByPatientIdAndTargetDate',
      method: 'get',
      params,
      paramsSerializer: params => {
        return qs.stringify(params, {arrayFormat: 'repeat'})
      }
    })
  }

  findSportTrendDataByPatientIdAndRangeDate (params, data) {
    return request({
      url: '/api/IotHealthData/findSportTrendDataByPatientIdAndRangeDate',
      method: 'post',
      params,
      data,
      paramsSerializer: params => {
        return qs.stringify(params, {arrayFormat: 'repeat'})
      }
    })
  }

  findSportTrendDataByPatientIdAndTargetDate (params) {
    return request({
      url: '/api/IotHealthData/findSportTrendDataByPatientIdAndTargetDate',
      method: 'get',
      params,
      paramsSerializer: params => {
        return qs.stringify(params, {arrayFormat: 'repeat'})
      }
    })
  }

  queryAllBloodOxygenByPatientIdAndTargetDateWithPage (params, data) {
    return request({
      url: '/api/IotHealthData/queryAllBloodOxygenByPatientIdAndTargetDateWithPage',
      method: 'post',
      params,
      data,
      paramsSerializer: params => {
        return qs.stringify(params, {arrayFormat: 'repeat'})
      }
    })
  }

  queryAllBloodPressureByPatientIdAndTargetDateWithPage (params, data) {
    return request({
      url: '/api/IotHealthData/queryAllBloodPressureByPatientIdAndTargetDateWithPage',
      method: 'post',
      params,
      data,
      paramsSerializer: params => {
        return qs.stringify(params, {arrayFormat: 'repeat'})
      }
    })
  }
  queryAllHeartRateByPatientIdAndTargetDateWithPage (params, data) {
    return request({
      url: '/api/IotHealthData/queryAllHeartRateByPatientIdAndTargetDateWithPage',
      method: 'post',
      params,
      data,
      paramsSerializer: params => {
        return qs.stringify(params, {arrayFormat: 'repeat'})
      }
    })
  }

  queryAllSportByPatientIdAndTargetDateWithPage (params, data) {
    return request({
      url: '/api/IotHealthData/queryAllSportByPatientIdAndTargetDateWithPage',
      method: 'post',
      params,
      data,
      paramsSerializer: params => {
        return qs.stringify(params, {arrayFormat: 'repeat'})
      }
    })
  }

}


export { HealthDataApi as default };
