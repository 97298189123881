import * as types from './mutation-types';
import cache, { keys } from '../services/cache';
import bus from '../services/bus-event';

export const init = (store) => {
    console.log('Starting app.');

    let promises = [];
    let loadStateMap = [
        {
            key: keys.TOKEN_KEY,
            mutation: 'auth/' + types.AUTH_SET_TOKEN
        },
        {
            key: keys.REFRESH_TOKEN_KEY,
            mutation: 'auth/' + types.AUTH_SET_REFRESH_TOKEN
        },
        {
            key: keys.USER_KEY,
            mutation: 'auth/' + types.AUTH_SET_USER
        }
    ];

    loadStateMap.forEach(obj => {
        var p = new Promise((resolve) => {
            cache.getItem(obj.key).then((value) => {
                store.commit(obj.mutation, value);
                resolve();
            }).catch(() => {
                resolve();
            });
        });
        promises.push(p);
    });

    return Promise.all(promises).then(() => {
        if (store.state.auth.token) {
            store.dispatch('auth/retoken').then(() => startApp(store));
        } else {
            startApp(store);
        }
    });
};


export const startApp = (store) => {
    if (store.state.auth.auth) {
        store.dispatch('auth/fetchUser');
    }

    bus.emit('system-ready');
};

