import * as types from '../mutation-types';
import { HomeDoctorApi } from '../../services/api';

let homeDoctorApi = new HomeDoctorApi();

const state = {
    currentHomeDoctor: null,
    homeDoctors: []
};

const getters = {
  currentHomeDoctor: state => state.currentHomeDoctor,
  homeDoctors: state => state.homeDoctors
};

const actions = {
  query(store, { params, data }) {
    return new Promise((resolve, reject) => {
      homeDoctorApi.query(params, data).then(response => {
        store.commit(types.HOMEDOCTORS_SET, response.data.content)
        resolve(response.data)
      }).catch(error => {
        reject(error)
      })
    })
  },

  create (store, data) {
    return homeDoctorApi.create(data).then(response => {
      store.commit(types.HOMEDOCTORS_ADD, response.data);
      return Promise.resolve(response.data);
    }).catch(err => {
      if (err) {
        return Promise.reject(err);
      }
    });
  },

  update (store, data) {
    return homeDoctorApi.update(data).then(response => {
      store.commit(types.HOMEDOCTORS_UPDATE, response.data);
      return Promise.resolve(response.data);
    }).catch(err => {
      if (err) {
        return Promise.reject(err);
      }
    });
  },

  delete (store, data = {}) {
    return homeDoctorApi.delete(data).then( () => {
      store.commit(types.HOMEDOCTORS_DELETE, data);
      return Promise.resolve();
    }).catch(err => {
      if (err) {
        return Promise.reject(err);
      }
    });
  },

  show (store, id = null) {
    return homeDoctorApi.show(id).then( response => {
      store.commit(types.HOMEDOCTORS_SET_CURRENT, response.data);
      return Promise.resolve();
    }).catch(err => {
      if (err) {
        return Promise.reject(err);
      }
    });
  }

};



const mutations = {
  [types.HOMEDOCTORS_SET_CURRENT] (state, homeDoctor) {
      state.currentHomeDoctor = homeDoctor;
  },
  [types.HOMEDOCTORS_ADD] (state, homeDoctor) {
      if (!state.homeDoctors) {
          state.homeDoctors = [];
      }
      state.homeDoctors.push(homeDoctor);
  },
  [types.HOMEDOCTORS_SET] (state, items) {
      state.homeDoctors = items;
  },
  [types.HOMEDOCTORS_UPDATE] (state, homeDoctor) {
      for (let i = 0; i < state.homeDoctors.length; i++) {
          if (state.homeDoctors[i].id === homeDoctor.id) {
              state.homeDoctors.splice(i, 1, homeDoctor)
          }
      }
  },
  [types.HOMEDOCTORS_DELETE] (state, homeDoctor) {
      state.homeDoctors = state.homeDoctors.filter(item => item.id !== homeDoctor.id);
  }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
