import * as types from '../mutation-types';
import { TemperatureMonitorApi } from '../../services/api';

let temperatureMonitorApi = new TemperatureMonitorApi();

const state = {
  detailsDatas: [],
  monitorDatas: [],
  summaryDatas: [],
  trendsDatas: []
};

const getters = {
  detailsDatas: state => state.detailsDatas,
  monitorDatas: state => state.monitorDatas,
  summaryDatas: state => state.summaryDatas,
  trendsDatas: state => state.trendsDatas
};

const actions = {

    queryTemperatureDetailsData (store, { params, data }) {
    return new Promise((resolve, reject) => {
      temperatureMonitorApi.queryTemperatureDetailsData(params, data).then(response => {
        store.commit(types.TEMPERATURE_DETAILSDATAS_SET, response.data.content)
        resolve(response.data)
      }).catch(error => {
        reject(error)
      })
    })
  },

  queryTemperatureMonitorData (store, data) {
    return new Promise((resolve, reject) => {
      temperatureMonitorApi.queryTemperatureMonitorData(data).then(response => {
        store.commit(types.TEMPERATURE_MONITORDATAS_SET, response.data)
        resolve(response.data)
      }).catch(error => {
        reject(error)
      })
    })
  },


  queryTemperatureSummaryData (store, data) {
    return new Promise((resolve, reject) => {
      temperatureMonitorApi.queryTemperatureSummaryData(data).then(response => {
        store.commit(types.TEMPERATURE_SUMMARYDATAS_SET, response.data)
        resolve(response.data)
      }).catch(error => {
        reject(error)
      })
    })
  },

  queryTemperatureTrendsData (store, data) {
    return new Promise((resolve, reject) => {
      temperatureMonitorApi.queryTemperatureTrendsData(data).then(response => {
        store.commit(types.TEMPERATURE_TRENDSDATAS_SET, response.data)
        resolve(response.data)
      }).catch(error => {
        reject(error)
      })
    })
  }

};


const mutations = {
  [types.TEMPERATURE_DETAILSDATAS_SET] (state, items) {
    state.detailsDatas = items;
  },

  [types.TEMPERATURE_MONITORDATAS_SET] (state, items) {
    state.monitorDatas = items;
  },

  [types.TEMPERATURE_SUMMARYDATAS_SET] (state, items) {
    state.summaryDatas = items;
  },

  [types.TEMPERATURE_TRENDSDATAS_SET] (state, items) {
    state.trendsDatas = items;
  }

};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
