import request from '../network'

class UserApi {

  query (params, data) {
    return request({
      url: '/api/users/query',
      method: 'post',
      params,
      data
    })
  }

  create (data = {}) {
    return request({
      url: '/api/users',
      method: 'post',
      data
    })
  }

  update (data = {}) {
    return request({
      url: '/api/users/' + data.id,
      method: 'put',
      data
    })
  }

  delete (data = {}) {
    return request({
      url: '/api/users/' + data.id,
      method: 'delete',
      data
    })
  }

  show (id) {
    if (!id) {
        id = 'self';
    }
    return request({
      url: '/api/users/' + id,
      method: 'get'
    })
  }

  queryAllByDepId (depId) {
    return request({
      url: '/api/users/queryAllByDep/' + depId,
      method: 'get'
    })
  }

  resetPassword (data) {
    return request({
      url: '/api/users/actions/reset-password',
      method: 'post',
      data
    })
  }

  setMonitorScreenUser (id) {
    return request({
      url: '/api/users/actions/setMonitorScreenUser/' + id,
      method: 'get'
    })
  }

  unSetMonitorScreenUser (id) {
    return request({
      url: '/api/users/actions/unSetMonitorScreenUser/' + id,
      method: 'get'
    })
  }

  setEpidemicMonitorUser (id) {
    return request({
      url: '/api/users/actions/setEpidemicMonitorUser/' + id,
      method: 'get'
    })
  }

  unSetEpidemicMonitorUser (id) {
    return request({
      url: '/api/users/actions/unSetEpidemicMonitorUser/' + id,
      method: 'get'
    })
  }
}


export { UserApi as default };
