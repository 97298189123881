import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from './routes.js';
import store from '../store';

Vue.use(VueRouter)

const router = new VueRouter({
  routes
})

const whiteList = ['/login', '/auth-redirect', '/site-policy/user-agreement']

router.beforeEach((to, from, next) => {
  let logined = store.getters['auth/checkLogin'];
  if (logined) {
    next()
  } else {
    if (whiteList.indexOf(to.path) !== -1) {
      next()
    } else {
      next(`/login?redirect=${to.path}`)
    }
  }
})


export default router
