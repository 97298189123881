import { LayoutDefault } from '@/components/layouts'

export default [
	{
    path: '',
    name: 'default',
    meta: {
      hiddenInMenu: true
    },
    component: LayoutDefault,
    children: [
      {
        path: '/',
        name: 'root',
        meta: {
          hiddenInMenu: true
        },
        redirect: '/temperature'
      },
      {
        path: '/home',
        name: 'home',
        meta: {
          hiddenInMenu: true
        },
        component: () => import('@/views/Home.vue')
      },
      {
        path: '/403',
        name: 'forbidden',
        meta: {
          hiddenInMenu: true
        },
        component: () => import('@/views/errors/Deny.vue')
      },

      {
        path: '/404',
        name: 'notFound',
        meta: {
          hiddenInMenu: true
        },
        component: () => import('@/views/errors/NotFound.vue')
      },

      {
        path: '/500',
        name: 'serverError',
        meta: {
          hiddenInMenu: true
        },
        component: () => import('@/views/errors/ServerError.vue')
      },
      {
        path: '/about',
        name: 'about',
        component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
        meta: {
          hiddenInMenu: true
        }
      }
    ]
  },

  {
    path: '/dashboard',
    name: 'dashboard',
    meta: {
      icon: 'mdi-tablet-dashboard',
      hiddenInMenu: true,
      roles: ['ROLE_ADMIN', 'ROLE_MONITOR_SCREEN'],
      activeLevel: 'COUNTY'
    },
    component: () => import('@/views/dashboard/index.vue')
  },

  /*
  {
    path: '/dashboard1',
    name: 'dashboard',
    meta: {
      hiddenInMenu: true
    },
    component: () => import('@/views/dashboard/dashboard1.vue')
  },
  */

  {
    path: '/dashboard2',
    name: 'dashboard',
    meta: {
      icon: 'mdi-tablet-dashboard',
      hiddenInMenu: true,
      roles: ['ROLE_ADMIN', 'ROLE_MONITOR_SCREEN'],
      activeLevel: 'COUNTY'
    },
    component: () => import('@/views/dashboard/dashboard2.vue')
  },

  {
    path: '/site-policy/user-agreement',
    name: 'userAgreement',
    meta: {
      hiddenInMenu: true
    },
    component: () => import('@/views/sitePolicy/UserAgreement.vue')
  },
  {
    path: '/login',
    name: 'login',
    meta: {
      hiddenInMenu: true
    },
    component: () => import('@/views/auth/Login.vue')
  },

  {
    path: '/temperature',
    name: 'temperature',
    meta: {
      icon: 'mdi-face-recognition',
      roles: ['ROLE_ADMIN', 'ROLE_EPIDEMIC_MONITOR']

    },
    component: LayoutDefault,
    redirect: '/temperature/dashboard',
    children: [
      {
        path: 'dashboard',
        name: 'temperatureDashboard',
        meta: {
          hiddenInMenu: false,
          roles: ['ROLE_ADMIN', 'ROLE_EPIDEMIC_MONITOR']
        },
        component: () => import('@/views/temperature/Dashboard.vue')
      },
      {
        path: 'detail',
        name: 'temperatureDetail',
        meta: {
          hiddenInMenu: false,
          roles: ['ROLE_ADMIN', 'ROLE_EPIDEMIC_MONITOR']
        },
        component: () => import('@/views/temperature/Detail.vue')
      }
    ]
  },

  {
    path: '/call-center',
    name: 'callCenter',
    meta: {
      icon: 'mdi-face-agent',
      roles: ['ROLE_ADMIN', 'ROLE_DEP_ADMIN', 'ROLE_MONITOR_SCREEN']
    },
    component: LayoutDefault,
    redirect: '/call-center/list',
    children: [
      {
        path: 'map',
        name: 'callCenterMap',
        meta: {
          hiddenInMenu: false,
          roles: ['ROLE_ADMIN', 'ROLE_DEP_ADMIN']
        },
        component: () => import('@/views/callCenter/Map.vue')
      },{
          path: 'list',
          name: 'callCenterList',
          meta: {
            hiddenInMenu: false,
            roles: ['ROLE_ADMIN']
          },
          component: () => import('@/views/callCenter/index.vue')
      },{
          path: 'show/:id(\\d+)',
          name: 'callCenterShow',
          props: true,
          meta: {
            hiddenInMenu: true,
            roles: ['ROLE_ADMIN']
          },
          component: () => import('@/views/callCenter/Detail.vue')
      }
    ]
  },
  {
    path: '/patient',
    name: 'patient',
    meta: {
      icon: 'mdi-account-circle-outline',
      roles: ['ROLE_ADMIN', 'ROLE_DEP_ADMIN']
    },
    component: LayoutDefault,
    redirect: '/patient/list',
    children: [
      {
        path: 'list',
        name: 'patientList',
        meta: {
          hiddenInMenu: false,
          roles: ['ROLE_ADMIN', 'ROLE_DEP_ADMIN']
        },
        component: () => import('@/views/patient/index.vue')
      },
      {
        path: 'create',
        name: 'patientCreate',
        meta: {
          hiddenInMenu: false,
          roles: ['ROLE_ADMIN', 'ROLE_DEP_ADMIN']
        },
        component: () => import('@/views/patient/Create.vue')
      },
      {
        path: 'edit/:id(\\d+)',
        name: 'patientEdit',
        props: true,
        meta: {
          hiddenInMenu: true,
          roles: ['ROLE_ADMIN', 'ROLE_DEP_ADMIN']
        },
        component: () => import('@/views/patient/Edit.vue')
      },
      {
        path: 'show/:id(\\d+)',
        name: 'patientShow',
        props: true,
        meta: {
          hiddenInMenu: true,
          roles: ['ROLE_ADMIN', 'ROLE_DEP_ADMIN']
        },
        component: () => import('@/views/patient/Detail.vue')
      },
      {
        path: 'sign/:id(\\d+)',
        name: 'patientSign',
        props: true,
        meta: {
          hiddenInMenu: true,
          roles: ['ROLE_ADMIN', 'ROLE_DEP_ADMIN']
        },
        component: () => import('@/views/patient/Sign.vue')
      },
      {
        path: ':patientId(\\d+)/health-reports',
        name: 'patientHealthReports',
        props: true,
        meta: {
          hiddenInMenu: true,
          roles: ['ROLE_ADMIN', 'ROLE_DEP_ADMIN']
        },
        component: () => import('@/views/healthReport/index.vue')
      },
      {
        path: 'health-report/:id(\\d+)',
        name: 'healthReportShow',
        props: true,
        meta: {
          hiddenInMenu: true,
          roles: ['ROLE_ADMIN', 'ROLE_DEP_ADMIN']
        },
        component: () => import('@/views/healthReport/Detail.vue')
      },
      {
        path: ':patientId(\\d+)/health-datas',
        name: 'patientHealthDatas',
        props: true,
        meta: {
          hiddenInMenu: true,
          roles: ['ROLE_ADMIN', 'ROLE_DEP_ADMIN']
        },
        component: () => import('@/views/healthReport/Data.vue')
      }
    ]
  },
  {
    path: '/contract',
    name: 'contract',
    meta: {
      icon: 'mdi-folder-text-outline',
      roles: ['ROLE_ADMIN']
    },
    component: LayoutDefault,
    redirect: '/contract/list',
    children: [
      {
        path: 'list',
        name: 'contractList',
        meta: {
          hiddenInMenu: false,
          roles: ['ROLE_ADMIN']

        },
        component: () => import('@/views/contract/index.vue')
      },
      {
        path: 'create',
        name: 'contractCreate',
        meta: {
          hiddenInMenu: true,
          roles: ['ROLE_ADMIN']
        },
        component: () => import('@/views/contract/Create.vue')
      },
      {
        path: 'edit/:id(\\d+)',
        name: 'contractEdit',
        props: true,
        meta: {
          hiddenInMenu: true,
          roles: ['ROLE_ADMIN']
        },
        component: () => import('@/views/contract/Edit.vue')
      }
    ]
  },
  {
    path: '/fee-rule',
    name: 'feeRule',
    meta: {
      icon: 'mdi-cash-100',
      roles: ['ROLE_ADMINS']
    },
    component: LayoutDefault,
    redirect: '/fee-rule/list',
    children: [
      {
        path: 'list',
        name: 'feeRuleList',
        meta: {
          hiddenInMenu: false,
          roles: ['ROLE_ADMIN']
        },
        component: () => import('@/views/feeRule/index.vue')
      },
      {
        path: 'create',
        name: 'feeRuleCreate',
        meta: {
          hiddenInMenu: true,
          roles: ['ROLE_ADMIN']
        },
        component: () => import('@/views/feeRule/Create.vue')
      },
      {
        path: 'edit/:id(\\d+)',
        name: 'feeRuleEdit',
        props: true,
        meta: {
          hiddenInMenu: true,
          roles: ['ROLE_ADMIN']
        },
        component: () => import('@/views/feeRule/Edit.vue')
      }
    ]
  },
  {
    path: '/home-doctor',
    name: 'homeDoctor',
    meta: {
      icon: 'mdi-doctor',
      roles: ['ROLE_ADMIN', 'ROLE_DEP_ADMIN']
    },
    component: LayoutDefault,
    redirect: '/home-doctor/list',
    children: [
      {
          path: 'list',
          name: 'homeDoctorList',
          meta: {
              hiddenInMenu: false,
              roles: ['ROLE_ADMIN', 'ROLE_DEP_ADMIN']
          },
          component: () => import('@/views/homeDoctor/index.vue')
      },
      {
          path: 'create',
          name: 'homeDoctorCreate',
          meta: {
              hiddenInMenu: false,
              roles: ['ROLE_ADMIN', 'ROLE_DEP_ADMIN']
          },
          component: () => import('@/views/homeDoctor/Create.vue')
      },
      {
          path: 'edit/:id(\\d+)',
          name: 'homeDoctorEdit',
          props: true,
          meta: {
              hiddenInMenu: true,
              roles: ['ROLE_ADMIN', 'ROLE_DEP_ADMIN']
          },
          component: () => import('@/views/homeDoctor/Edit.vue')
      }
    ]
  },
  {
    path: '/equipment',
    name: 'equipment',
    meta: {
      icon: 'mdi-barcode',
      roles: ['ROLE_ADMIN']
    },
    component: LayoutDefault,
    redirect: '/equipment/list',
    children: [
      {
        path: 'list',
        name: 'equipmentList',
        meta: {
          hiddenInMenu: false,
          roles: ['ROLE_ADMIN']
        },
        component: () => import('@/views/equipment/index.vue')
      },
      {
        path: 'distribute',
        name: 'equipmentDistribute',
        meta: {
          hiddenInMenu: false,
          roles: ['ROLE_ADMIN']
        },
        component: () => import('@/views/equipment/Distribute.vue')
      },
      {
        path: 'create',
        name: 'equipmentCreate',
        meta: {
          hiddenInMenu: false,
          roles: ['ROLE_ADMIN']
        },
        component: () => import('@/views/equipment/Create.vue')
      },
      {
        path: 'change/:id(\\d+)/:patientId(\\d+)',
        name: 'equipmentChange',
        props: true,
        meta: {
          hiddenInMenu: true,
          roles: ['ROLE_ADMIN']
        },
        component: () => import('@/views/equipment/Change.vue')
      },
      {
        path: 'template',
        name: 'equipmentTemplate',
        meta: {
          hiddenInMenu: false,
          roles: ['ROLE_ADMIN']
        },
        component: () => import('@/views/equipmentTemplate/index.vue')
      },
      {
        path: 'template/create',
        name: 'equipmentTemplateCreate',
        meta: {
          hiddenInMenu: true,
          roles: ['ROLE_ADMIN']
        },
        component: () => import('@/views/equipmentTemplate/Create.vue')
      },
      {
        path: 'template/edit/:id(\\d+)',
        name: 'equipmentTemplateEdit',
        props: true,
        meta: {
          hiddenInMenu: true,
          roles: ['ROLE_ADMIN']
        },
        component: () => import('@/views/equipmentTemplate/Edit.vue')
      }
    ]
  },

  {
    path: '/system',
    name: 'system',
    meta: {
      icon: 'mdi-cog-outline',
      roles: ['ROLE_ADMIN']
    },
    component: LayoutDefault,
    redirect: '/system/users',
    children: [
      {
        path: 'users',
        name: 'systemUser',
        meta: {
          hiddenInMenu: false,
          roles: ['ROLE_ADMIN']
        },
        component: () => import('@/views/system/user/index.vue')
      },
      {
        path: 'users/create',
        name: 'systemUserCreate',
        meta: {
          hiddenInMenu: true,
          roles: ['ROLE_ADMIN']
        },
        component: () => import('@/views/system/user/Create.vue')
      },
      {
        path: 'users/edit/:id(\\d+)',
        name: 'systemUserEdit',
        props: true,
        meta: {
          hiddenInMenu: true,
          roles: ['ROLE_ADMIN']
        },
        component: () => import('@/views/system/user/Edit.vue')
      },
      {
        path: 'reset-password',
        name: 'resetPassword',
        meta: {
          hiddenInMenu: true
        },
        component: () => import('@/views/auth/ResetPassword.vue')
      },
      {
        path: 'departments',
        name: 'systemDepartment',
        meta: {
          hiddenInMenu: false,
          roles: ['ROLE_ADMIN']
        },
        component: () => import('@/views/system/department/index.vue')
      },
      {
        path: 'roles',
        name: 'systemRole',
        meta: {
          hiddenInMenu: true,
          roles: ['ROLE_ADMIN']
        },
        component: () => import('@/views/system/role/index.vue')
      }
    ]
  }
]
