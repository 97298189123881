import * as types from '../mutation-types';
import { EquipmentApi } from '../../services/api';

let equipmentApi = new EquipmentApi();

const state = {
    currentEquipment: null,
    equipments: []
};

const getters = {
  currentEquipment: state => state.currentEquipment,
  equipments: state => state.equipments
};

const actions = {
  query(store, { params, data }) {
    return new Promise((resolve, reject) => {
      equipmentApi.query(params, data).then(response => {
        store.commit(types.EQUIPMENTS_SET, response.data.content)
        resolve(response.data)
      }).catch(error => {
        reject(error)
      })
    })
  },

  create (store, data) {
    return equipmentApi.create(data).then(response => {
      store.commit(types.EQUIPMENTS_ADD, response.data);
      return Promise.resolve(response.data);
    }).catch(err => {
      if (err) {
        return Promise.reject(err);
      }
    });
  },

  update (store, data) {
    return equipmentApi.update(data).then(response => {
      store.commit(types.EQUIPMENTS_UPDATE, response.data);
      return Promise.resolve(response.data);
    }).catch(err => {
      if (err) {
        return Promise.reject(err);
      }
    });
  },

  delete (store, data = {}) {
    return equipmentApi.delete(data).then( () => {
      store.commit(types.EQUIPMENTS_DELETE, data);
      return Promise.resolve();
    }).catch(err => {
      if (err) {
        return Promise.reject(err);
      }
    });
  },

  show (store, id = null) {
    return equipmentApi.show(id).then( response => {
      store.commit(types.EQUIPMENTS_SET_CURRENT, response.data);
      return Promise.resolve();
    }).catch(err => {
      if (err) {
        return Promise.reject(err);
      }
    });
  },

  grant (store, { id, params }) {
    return equipmentApi.grant(id, params).then( () => {
      return Promise.resolve();
    }).catch(err => {
      if (err) {
        return Promise.reject(err);
      }
    });
  },

  queryAllByDepId (store, depId = null) {
    return equipmentApi.queryAllByDepId(depId).then( response => {
      store.commit(types.EQUIPMENTS_SET, response.data);
      return Promise.resolve();
    }).catch(err => {
      if (err) {
        return Promise.reject(err);
      }
    });
  },

  downloadTemplate () {
    return equipmentApi.downloadTemplate().then( response => {
      return Promise.resolve(response);
    }).catch(err => {
      if (err) {
        return Promise.reject(err);
      }
    });
  },

  importById (store, { id, data }) {
    return equipmentApi.importById(id, data).then( response => {
      return Promise.resolve(response);
    }).catch(err => {
      if (err) {
        return Promise.reject(err);
      }
    });
  },

  queryAllAvailablePage (store, { params, data }) {
    return new Promise((resolve, reject) => {
      equipmentApi.queryAllAvailablePage(params, data).then(response => {
        store.commit(types.EQUIPMENTS_SET, response.data.content)
        resolve(response.data)
      }).catch(error => {
        reject(error)
      })
    })
  },

  change (store, { id, params }) {
    return equipmentApi.change(id, params).then( response => {
      return Promise.resolve(response);
    }).catch(err => {
      if (err) {
        return Promise.reject(err);
      }
    });
  },

  Recycling (store, { id, params }) {
    return new Promise((resolve, reject) => {
      equipmentApi.Recycling(id, params).then(response => {
        store.commit(types.EQUIPMENTS_RECYCLING, id)
        resolve(response.data)
      }).catch(error => {
        reject(error)
      })
    })
  }

};



const mutations = {
  [types.EQUIPMENTS_SET_CURRENT] (state, equipment) {
      state.currentEquipment = equipment;
  },
  [types.EQUIPMENTS_ADD] (state, equipment) {
      if (!state.equipments) {
          state.equipments = [];
      }
      state.equipments.push(equipment);
  },
  [types.EQUIPMENTS_SET] (state, items) {
      state.equipments = items;
  },
  [types.EQUIPMENTS_UPDATE] (state, equipment) {
      for (let i = 0; i < state.equipments.length; i++) {
          if (state.equipments[i].id === equipment.id) {
              state.equipments.splice(i, 1, equipment)
          }
      }
  },
  [types.EQUIPMENTS_DELETE] (state, equipment) {
      state.equipments = state.equipments.filter(item => item.id !== equipment.id);
  },
  [types.EQUIPMENTS_RECYCLING] (state, id) {
      for (let i = 0; i < state.equipments.length; i++) {
          if (state.equipments[i].id === id) {
              state.equipments[i].patient = null
              state.equipments[i].state = 'READY'
          }
      }
  }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
