import * as types from '../mutation-types';
import { MonitorScreenApi } from '../../services/api';

let monitorScreenApi = new MonitorScreenApi();

const state = {
  currentRealtimeData: null,
  currentSummaryLevel1: null,
  currentSummaryLevel2: null
};

const getters = {
  currentRealtimeData: state => state.currentRealtimeData,
  currentSummaryLevel1: state => state.currentSummaryLevel1,
  currentSummaryLevel2: state => state.currentSummaryLevel2
};

const actions = {

  queryMonitorRealtimeData(store) {
    return new Promise((resolve, reject) => {
      monitorScreenApi.queryMonitorRealtimeData().then(response => {
        store.commit(types.MONITORSCREEN_REALTIMEDATAS_SET_CURRENT, response.data)
        resolve(response.data)
      }).catch(error => {
        reject(error)
      })
    })
  },

  queryMonitorSummaryLevel1(store) {
    return new Promise((resolve, reject) => {
      monitorScreenApi.queryMonitorSummaryLevel1().then(response => {
        store.commit(types.MONITORSCREEN_SUMMARYLEVEL1_SET_CURRENT, response.data)
        resolve(response.data)
      }).catch(error => {
        reject(error)
      })
    })
  },

  queryMonitorSummaryLevel2(store) {
    return new Promise((resolve, reject) => {
      monitorScreenApi.queryMonitorSummaryLevel2().then(response => {
        store.commit(types.MONITORSCREEN_SUMMARYLEVEL2_SET_CURRENT, response.data)
        resolve(response.data)
      }).catch(error => {
        reject(error)
      })
    })
  }

};


const mutations = {

  [types.MONITORSCREEN_REALTIMEDATAS_SET_CURRENT] (state, item) {
    state.currentRealtimeData = item;
  },

  [types.MONITORSCREEN_SUMMARYLEVEL1_SET_CURRENT] (state, item) {
    state.currentSummaryLevel1 = item;
  },

  [types.MONITORSCREEN_SUMMARYLEVEL2_SET_CURRENT] (state, item) {
    state.currentSummaryLevel2 = item;
  }

};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
