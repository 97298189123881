import * as types from '../mutation-types';
import { DepartmentApi } from '../../services/api';

let departmentApi = new DepartmentApi();

const state = {
    departments: []
};

const getters = {
    departments: state => state.departments
};

const actions = {

  query(store, { params, data }) {
    return new Promise((resolve, reject) => {
      departmentApi.query(params, data).then(response => {
        store.commit(types.DEPARTMENTS_SET, response.data.content)
        resolve(response.data)
      }).catch(error => {
        reject(error)
      })
    })
  },

  queryAll(store) {
    return new Promise((resolve, reject) => {
      departmentApi.queryAll().then(response => {
        store.commit(types.DEPARTMENTS_SET, response.data)
        resolve()
      }).catch(error => {
        reject(error)
      })
    })
  },

  create (store, data) {
    return departmentApi.create(data).then(response => {
      store.commit(types.DEPARTMENTS_ADD, response.data);
      return Promise.resolve(response.data);
    }).catch(err => {
      if (err) {
        return Promise.reject(err);
      }
    });
  },

  update (store, data) {
    return departmentApi.update(data).then(response => {
      store.commit(types.DEPARTMENTS_UPDATE, response.data);
      return Promise.resolve(response.data);
    }).catch(err => {
      if (err) {
        return Promise.reject(err);
      }
    });
  },

  delete (store, data = {}) {
    return departmentApi.delete(data).then( () => {
      store.commit(types.DEPARTMENTS_DELETE, data);
      return Promise.resolve();
    }).catch(err => {
      if (err) {
        return Promise.reject(err);
      }
    });
  },

  resetAdmin (store, { id, params }) {
    return departmentApi.resetAdmin(id, params).then( () => {
      store.commit(types.DEPARTMENTS_RESET_ADMIN, {id, userId: params.userId})
      return Promise.resolve();
    }).catch(err => {
      if (err) {
        return Promise.reject(err);
      }
    });
  },

  setStoreDepAdmin (store, { id, userId }) {
    return  new Promise((resolve) => {
      store.commit(types.DEPARTMENTS_RESET_ADMIN, { id, userId })
      return resolve()
    });
  }

};



const mutations = {
    [types.DEPARTMENTS_ADD] (state, dep) {
        if (!state.departments) {
            state.departments = [];
        }
        state.departments.push(dep);
    },
    [types.DEPARTMENTS_SET] (state, items) {
        state.departments = items;
    },
    [types.DEPARTMENTS_UPDATE] (state, dep) {
        for (let i = 0; i < state.departments.length; i++) {
            if (state.departments[i].id === dep.id) {
                state.departments[i] = dep;
            }
        }
    },
    [types.DEPARTMENTS_RESET_ADMIN] (state, { id, userId }) {
      for (let i = 0; i < state.departments.length; i++) {
          if (state.departments[i].id === id) {
            let dep = state.departments[i]
            dep.depAdminIds = [ userId ]
            state.departments.splice(i, 1, dep)
          }
      }
    },
    [types.DEPARTMENTS_DELETE] (state, dep) {
        state.departments = state.departments.filter(item => item.id !== dep.id);
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
