import request from '../network'

class TemperatureMonitorApi {

  queryTemperatureDetailsData (params, data) {
    return request({
      url: '/api/TemperatureMonitor/queryTemperatureDetailsData',
      method: 'post',
      params,
      data
    })
  }

  queryTemperatureMonitorData (data) {
    return request({
      url: '/api/TemperatureMonitor/queryTemperatureMonitorData',
      method: 'post',
      data
    })
  }

  queryTemperatureSummaryData (data) {
    return request({
      url: '/api/TemperatureMonitor/queryTemperatureSummaryData',
      method: 'post',
      data
    })
  }

  queryTemperatureTrendsData (data) {
    return request({
      url: '/api/TemperatureMonitor/queryTemperatureTrendsData',
      method: 'post',
      data
    })
  }

}


export { TemperatureMonitorApi as default };
