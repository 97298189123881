import request from '../network'

class ParamInfoApi {

  queryAllByTypeCode (typeCode) {
    return request({
      url: `/api/ParamInfo/list/${ typeCode }` ,
      method: 'get'
    })
  }

}


export { ParamInfoApi as default };
