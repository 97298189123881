import * as types from '../mutation-types';
import { FeeRuleApi } from '../../services/api';

let feeRuleApi = new FeeRuleApi();

const state = {
    currentFeeRule: null,
    feeRules: []
};

const getters = {
  currentFeeRule: state => state.currentFeeRule,
  feeRules: state => state.feeRules
};

const actions = {
  query(store, { params, data }) {
    return new Promise((resolve, reject) => {
      feeRuleApi.query(params, data).then(response => {
        store.commit(types.FEERULES_SET, response.data.content)
        resolve(response.data)
      }).catch(error => {
        reject(error)
      })
    })
  },

  queryAll(store) {
    return new Promise((resolve, reject) => {
      feeRuleApi.queryAll().then(response => {
        store.commit(types.FEERULES_SET, response.data)
        resolve()
      }).catch(error => {
        reject(error)
      })
    })
  },

  create (store, data) {
    return feeRuleApi.create(data).then(response => {
      store.commit(types.FEERULES_ADD, response.data);
      return Promise.resolve(response.data);
    }).catch(err => {
      if (err) {
        return Promise.reject(err);
      }
    });
  },

  update (store, data) {
    return feeRuleApi.update(data).then(response => {
      store.commit(types.FEERULES_UPDATE, response.data);
      return Promise.resolve(response.data);
    }).catch(err => {
      if (err) {
        return Promise.reject(err);
      }
    });
  },

  delete (store, data = {}) {
    return feeRuleApi.delete(data).then( () => {
      store.commit(types.FEERULES_DELETE, data);
      return Promise.resolve();
    }).catch(err => {
      if (err) {
        return Promise.reject(err);
      }
    });
  },

  show (store, id = null) {
    return feeRuleApi.show(id).then( response => {
      store.commit(types.FEERULES_SET_CURRENT, response.data);
      return Promise.resolve();
    }).catch(err => {
      if (err) {
        return Promise.reject(err);
      }
    });
  }

};



const mutations = {
  [types.FEERULES_SET_CURRENT] (state, feeRule) {
      state.currentFeeRule = feeRule;
  },
  [types.FEERULES_ADD] (state, feeRule) {
      if (!state.feeRules) {
          state.feeRules = [];
      }
      state.feeRules.push(feeRule);
  },
  [types.FEERULES_SET] (state, items) {
      state.feeRules = items;
  },
  [types.FEERULES_UPDATE] (state, feeRule) {
      for (let i = 0; i < state.feeRules.length; i++) {
          if (state.feeRules[i].id === feeRule.id) {
              state.feeRules.splice(i, 1, feeRule)
          }
      }
  },
  [types.FEERULES_DELETE] (state, feeRule) {
      state.feeRules = state.feeRules.filter(item => item.id !== feeRule.id);
  }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
